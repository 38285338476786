import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoUsuario } from 'src/app/models/tipo-usuario';
import { TipoUsuarioService } from 'src/app/services/tipo-usuario.service';

@Component({
  selector: 'app-select-tipos-usuario',
  templateUrl: './select-tipos-usuario.component.html',
  styleUrls: ['./select-tipos-usuario.component.css'],
  providers: [
    TipoUsuarioService
  ]
})
export class SelectTiposUsuarioComponent implements OnInit {

  @Input()
  public tipoUsuario: TipoUsuario = new TipoUsuario();
  public tiposUsuario: Array<TipoUsuario> = new Array<TipoUsuario>();
  @Output()
  public tipoUsuarioSeleccionado: EventEmitter<TipoUsuario> = new EventEmitter<TipoUsuario>();

  constructor(
    private _tipoUsuarioService: TipoUsuarioService
  ) { }

  ngOnInit(): void {
    this.consultarTiposUsuario();
  }

  public consultarTiposUsuario(){
    this.tiposUsuario = new Array<TipoUsuario>();

    this.tiposUsuario.push(
      new TipoUsuario(
        0,
        'Seleccione un tipo de persona'
      )
    );

    this._tipoUsuarioService.consultarTodos().subscribe(
      result => {
        if(result.resultado){
          result.datos.forEach(objTipoUsuario => {
            const tipoUsuario: TipoUsuario = new TipoUsuario();
            tipoUsuario.inicializar(objTipoUsuario);

            this.tiposUsuario.push(tipoUsuario);
          });
        }
      }
    );
  }

  public onTipoSeleccionado(){
    let tipoUsuario = this.tiposUsuario.find(tipoUsuario => tipoUsuario.id == this.tipoUsuario.id);
    this.tipoUsuarioSeleccionado.emit(tipoUsuario);
  }

}