import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';
import { Producto } from 'src/app/models/producto';
import { Utilidades } from 'src/app/models/utilidades';
import { ProductoService } from 'src/app/services/producto.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  },
})
export class ProductosComponent implements OnInit, AfterViewInit {

  public producto: Producto = new Producto();
  public productos: Array<any> = new Array<any>();
  public filtroProductos: string = '';
  public fechaInicialInformeSalidas: Date;
  public fechaFinalInformeSalidas: Date;
  public croppedResult: string = '';
  public nombreArchivo: string = '';
  public imagen: string = '';

  /**
   * Propiedades del diseño
   */

  @ViewChild('divForm')
  public divForm: ElementRef;
  public altoDivTabla: number;
  public modalInformeSalidas: boolean = false;
  public modalComprasActivo: boolean = false;
  protected iconoFile: string = 'fas fa-upload';
  @ViewChild('imagenCropped')
  public imagenCropped: ElementRef;
  @ViewChild('angularCropper')
  public angularCropper: CropperComponent;
  public modalCropper: boolean = false;
  public subiendoImagen: boolean = false;
  @ViewChild('referencia')
  public referencia: ElementRef;
  @ViewChild('detalle')
  public detalle: ElementRef;
  public thReferencia: number;
  public thDetalle: number;

  public labels = {
    producto: 'Producto',
    referencia: 'Referencia',
    detalle: 'Detalle',
    cantidad: 'Cantidad',
    cantidadInterna: 'Cantidad interna',
    cantidadDisponible: 'Cantidad disponible',
    precio: 'Precio unitario',
    buscar: 'Buscar por código o descripción',
    rangoFechas: 'Rango de fechas',
    fechaInicial: 'Fecha inicial',
    fechaFinal: 'Fecha final',
    agregarCompra: 'Agregar ingreso de producto',
    imagen: 'Imagen del producto',
    seleccionarImagen: 'Seleccionar imagen',
    destacado: 'Producto destacado'
  };

  public botones = {
    guardar: 'Guardar',
    eliminar: 'Eliminar',
    informeSalidas: 'Informe de salidas',
    informeExistencias: 'Informe de existencias',
    generarInforme: 'Generar informe',
    aceptar: 'Aceptar'
  };

  public columnas = {
    referencia: 'Referencia',
    detalle: 'Detalle',
    acciones: 'Acciones'
  }

  public mensajes = {
    preguntaEliminar: '¿Está seguro de eliminar el registro? Esta opción no se puede deshacer.',
    productoCreado: 'El producto se ha creado correctamente',
    productoActualizado: 'El producto se ha actualizado correctamente',
    noHaySalidas: 'No existen facturas ni préstamos en el rango de fechas ingresado'
  }

  constructor(
    private _productoService: ProductoService
  ) { }

  ngOnInit(): void {
    this.consultarProductos();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  public onResize() {
    this.setColumnWidth();

    if (this.divForm) {
      let altoDivForm: number = this.divForm.nativeElement.offsetHeight;
      let altoDivTabla = window.innerHeight - altoDivForm - 240;

      if (altoDivTabla != this.altoDivTabla) {
        this.altoDivTabla = altoDivTabla;
      }
    }
  }

  public consultarProductos() {
    this._productoService.consultarPorSucursal().subscribe(
      result => {
        if (result.resultado) {
          this.productos = result.datos;
        }
      }
    );
  }

  public consultarPorId(id: number) {
    this._productoService.consultarProductoPorId(id).subscribe(
      result => {
        if (result.resultado) {
          this.producto = new Producto();
          this.producto.inicializar(result.datos);
          this.croppedResult = 'https://appdecoraytransforma.co/demo/decora_transforma/documentos/' + result.datos.nombre_imagen + '.png';

          if (result.datos.nombre_imagen != '') {
            this.iconoFile = 'fas fa-check';
          }
        }
      }
    );
  }

  public deshabilitarProducto() {
    Utilidades.dialogPregunta('', this.mensajes.preguntaEliminar, 'Sí, eliminar').then((result) => {
      this._productoService.deshabilitarProducto(this.producto.id).subscribe(
        result => {
          if (result.resultado) {
            this.producto = new Producto();
            this.consultarProductos();
          }
        }
      );
    });
  }

  public limpiarProducto() {
    this.producto = new Producto();
    this.croppedResult = '';
    this.iconoFile = 'fas fa-upload'
  }

  public guardar() {
    if (this.producto.id == 0) {
      this.crear();
    } else {
      this.actualizar();
    }
  }

  private crear() {
    this._productoService.crear(this.producto).subscribe(
      result => {
        if (result.resultado) {
          this.producto.id = result.datos.id;
          Utilidades.dialogSuccess(this.mensajes.productoCreado);
          this.consultarProductos();
        }
      }
    );
  }

  private actualizar() {
    this._productoService.actualizar(this.producto).subscribe(
      result => {
        if (result.resultado) {
          Utilidades.dialogSuccess(this.mensajes.productoActualizado);
          this.consultarProductos();
        }
      }
    );
  }

  public generarInformeSalidas() {
    this._productoService.consultarInformeSalidas(this.fechaInicialInformeSalidas, this.fechaFinalInformeSalidas).subscribe(
      result => {
        if (result.resultado) {
          Utilidades.dialogImprimir(result.datos.ruta);
        } else {
          Utilidades.dialogInfo(this.mensajes.noHaySalidas)
        }
      }
    );
  }

  public generarInformeExistencias() {
    this._productoService.consultarInformeExistencias().subscribe(
      result => {
        Utilidades.dialogImprimir(result.datos.ruta);
      }
    );
  }

  public abrirModalInformeSalidas() {
    this.modalInformeSalidas = true;
  }

  public cerrarModalInformeSalidas() {
    this.modalInformeSalidas = false;
  }

  public mostrarModalCompras(visible: boolean) {
    if (visible == false) {
      this.consultarPorId(this.producto.id);
    }

    this.modalComprasActivo = visible;
  }

  protected setColumnWidth() {
    if (this.referencia) {
      this.thReferencia = this.referencia.nativeElement.offsetWidth;
      this.thDetalle = this.detalle.nativeElement.offsetWidth;
    }
  }

  public onFilterTextChanged(filterText: string) {
    this.filtroProductos = filterText;
  }

  public archivoSeleccionado(event: Event): void {
    this.croppedResult = '';
    this.iconoFile = 'fas fa-spinner fa-spin';
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen = reader.result as string;
      };
      reader.readAsDataURL(inputElement.files[0]);
      this.nombreArchivo = inputElement.files[0].name;
    } else {
      this.imagen = '';
      this.nombreArchivo = '';
    }
  }

  public abrirModalCropper() {
    this.modalCropper = true;
  }

  public cerrarModalCropper() {
    this.modalCropper = false;
  }

  public getCroppedImage() {
    const croppedCanvas = this.angularCropper.cropper.getCroppedCanvas();
    const maxDimension = 290;

    if (croppedCanvas.width > maxDimension || croppedCanvas.height > maxDimension) {
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = maxDimension;
      resizedCanvas.height = maxDimension;
      ctx.drawImage(croppedCanvas, 0, 0, maxDimension, maxDimension);

      this.croppedResult = resizedCanvas.toDataURL();
    } else {
      this.croppedResult = croppedCanvas.toDataURL();
    }

    fetch(this.croppedResult)
      .then(res => res.blob())
      .then(blob => {
        const imagen: File = new File([blob], "imagen.png", { type: "image/png" })
        this.subiendoImagen = true;

        this._productoService.subirImagen(imagen).subscribe(
          result => {
            this.subiendoImagen = false;
            this.iconoFile = 'fas fa-check';
            if (result.resultado) {
              this.producto.nombreImagen = result.datos.nombre;
            }
          }
        );
      });

    this.cerrarModalCropper();
  }

  public establecerDestacado(producto: Producto, checked) {
    producto.destacado = checked;
  }

}