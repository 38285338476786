import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MedioPago } from 'src/app/models/medio-pago';
import { MedioPagoService } from 'src/app/services/medio-pago.service';

@Component({
  selector: 'app-select-medios-pago',
  templateUrl: './select-medios-pago.component.html',
  styleUrls: ['./select-medios-pago.component.css']
})
export class SelectMediosPagoComponent implements OnInit {

  protected mediosPago: Array<MedioPago> = [];
  protected medioPago: MedioPago = new MedioPago();
  @Output()
  public medioPagoSeleccionado: EventEmitter<MedioPago> = new EventEmitter<MedioPago>();

  constructor(
    private _medioPagoService: MedioPagoService
  ) { }

  ngOnInit(): void {
    this.consultarListado();
  }

  protected consultarListado() {
    this.mediosPago = [];

    this._medioPagoService.consultarListado().subscribe(
      result => {
        if(result.resultado) {
          result.datos.forEach(element => {
            const medioPago = new MedioPago();
            medioPago.inicializar(element);

            this.mediosPago.push(medioPago);
          });

          this.medioPago = this.mediosPago[0];
          this.onMedioPagoSeleccionado();
        }
      }
    );
  }

  public onMedioPagoSeleccionado(){
    this.medioPagoSeleccionado.emit(
      Object.assign(new MedioPago(), this.medioPago)
    );
  }

  public setMedioPago(value: MedioPago) {
    this.medioPago = this.mediosPago.find(
      medioPago => medioPago.id == value.id
    );
  }
}