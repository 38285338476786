<div #divForm class="form-filtros">

    <form autocomplete="off">

        <!-- Fecha -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.fecha}}</label>
            </div>
            <div class="field field-body">
                
                <div class="control">

                    <input 
                        class="input is-small"
                        type="date"
                        name="fecha"
                        [(ngModel)]="prestamo.fecha">

                </div>

            </div>
        </div>

        <!-- FIN: Fecha -->

        <!-- Fecha de entrega -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label for="" class="level-left">{{labels.fechaEntrega}}</label>
            </div>
            <div class="field field-body">
                <div class="control">
                    <input 
                        class="input is-small"
                        type="date" 
                        name="fechaEntrega"
                        [(ngModel)]="prestamo.fechaEntrega"
                        disabled>
                </div>
            </div>
        </div>

        <!-- FIN: Fecha de entrega -->

        <!-- Distribuidor -->

        <app-txt-persona
            #txtDistribuidor
            [labelPersona]="labels.distribuidor"
            [tipoPersona]="tipoPersonaDistribuidor"
            [persona]="prestamo.distribuidor"
            (personaSeleccionada)="distribuidorSeleccionado($event)">
        </app-txt-persona>

        <!-- FIN: Distribuidor -->

        <!-- Empleado -->

        <app-txt-persona #txtEncargado
            [labelPersona]="labels.encargado"
            [consultarEmpleados]="true"
            [persona]="prestamo.encargado"
            (personaSeleccionada)="encargadoSeleccionado($event)">
        </app-txt-persona>

        <!-- FIN: Empleado -->

        <!-- Notas -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.notas}}</label>
            </div>
            <div class="field-body">

            <textarea 
                class="textarea is-small"
                rows="2"
                name="notas"
                [(ngModel)]="prestamo.notas">
            </textarea>

            </div>
        </div>

        <!-- FIN: Notas -->

        <!-- Detalles -->

        <div class="box">

            <div class="field is-grouped">
                
                <!-- Cantidad -->

                <div class="control txt-pequenno">
                    
                    <input 
                        class="input is-small"
                        type="number"
                        placeholder="{{labels.cantidad}}"
                        name="cantidad"
                        [(ngModel)]="detalleActual.cantidad">

                </div>
                
                <!-- FIN: Cantidad -->

                <!-- Producto -->

                <div class="control">
                    <app-select-productos
                        (productoSeleccionado)="productoSeleccionado($event)">
                    </app-select-productos>
                </div>

                <!-- Producto -->

                <div class="control">
                    <button 
                        class="button is-small landing"
                        (click)="agregarDetalle()">
                        <span class="icon is-small">
                            <i class="fas fa-plus"></i>
                        </span>
                    </button>
                </div>

            </div>

        </div>

        <!-- FIN: Detalles -->

    </form>

</div>

<div #divTablaDetalles class="div-tabla is-size-7" [style.height.px]="altoTablaDetalles">

    <table class="table is-fullwidth is-bordered">

        <thead>
            <tr>
                <th width="10%">{{columnas.cantidad}}</th>
                <th width="10%">{{columnas.referencia}}</th>
                <th>{{columnas.producto}}</th>
                <th width="10%">{{columnas.acciones}}</th>
            </tr>
        </thead>

        <tbody>

            <tr *ngFor="let detalle of prestamo.detalles; let i = index">
                <td>{{detalle.cantidad}}</td>
                <td>{{detalle.producto.referencia}}</td>
                <td>{{detalle.producto.detalle}}</td>
                <td>
                    <div class="buttons is-centered">
                        <button 
                            class="button is-small is-danger"
                            (click)="eliminarDetalle(detalle, i)">
                            <span class="icon is-small">
                                <i class="fas fa-trash-alt"></i>
                            </span>
                        </button>
                    </div>
                </td>
            </tr>

        </tbody>

    </table>

</div>

<br>

<!-- Botones -->

<div class="field is-grouped is-grouped-right">

    <div class="control">
        <button 
            class="button is-small is-outlined"
            (click)="guardar()">
            {{botones.guardar}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-outlined"
            (click)="abrirModalDevolucion()"
            [disabled]="prestamo.id == 0">
            {{botones.registrarEntrega}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-danger"
            (click)="eliminar()"
            [disabled]="prestamo.id == 0">
            {{botones.eliminar}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small"
            (click)="limpiar()">
            <span class="icon is-small has-text-primary">
                <i class="fas fa-eraser"></i>
            </span>
        </button>
    </div>

</div>

<!-- FIN: Botones -->

<!-- Modal devolución -->

<div [ngClass]="modalDevolucionActivo ? 'modal is-active' : 'modal'">
    <div 
        class="modal-background"
        (click)="cerrarModalDevolucion()"></div>
    <div class="modal-content modal-listado">
        <div class="box">

            <!-- Fecha de entrega -->

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label for="" class="level-left">{{labels.fechaEntrega}}</label>
                </div>
                <div class="field field-body">
                    <div class="control">
                        <input 
                            class="input"
                            type="date" 
                            name="fechaEntrega"
                            [(ngModel)]="prestamo.fechaEntrega">
                    </div>
                </div>
            </div>

            <div class="field is-grouped is-grouped-right">
                <div class="control">
                    <button 
                        class="button is-small is-outlined"
                        (click)="guardar()">
                        {{botones.registrarEntrega}}
                    </button>
                </div>
            </div>

            <!-- FIN: Fecha de entrega -->

        </div>
    </div>
</div>

<!-- FIN: Modal devolución -->