import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginacion',
  templateUrl: './paginacion.component.html',
  styleUrls: ['./paginacion.component.css']
})
export class PaginacionComponent implements OnInit {

  protected paginas: Array<number> = new Array<number>();
  public cantidadVisible: number = 25;
  public cantidadElementos: number = 0;
  public cantidadTotalElementos: number = 0;
  @Input()
  protected nombreElementos: string = '';
  @Output()
  public cantidadSeleccionada: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public paginaSeleccionada: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Propiedades de visualización de botones,
   * inicio y fin son las posiciones (NO los items)
   */
  protected inicioVisible: number = 0;
  public paginaActual: number = 1;
  protected finVisible: number = 2;

  protected labels = {
    todosElementos: 'Todos',
    paginaActual: '',
    elementosVisibles: ''
  };

  public constructor() { }

  ngOnInit(): void {

  }

  public establecerPaginas() {
    this.paginas = [];
    let cantidadPaginas = 1;

    if (this.cantidadVisible != 0) {
      cantidadPaginas = Math.round(this.cantidadTotalElementos / this.cantidadVisible)

      if ((this.cantidadTotalElementos / this.cantidadVisible) > cantidadPaginas) {
        cantidadPaginas += 1;
      }
    }

    for (let index = 0; index < cantidadPaginas; index++) {
      this.paginas.push(index + 1);
    }

    this.labels.paginaActual = `Página ${this.paginaActual} de ${this.paginas.length}. Cantidad: ${this.cantidadElementos} de ${this.cantidadTotalElementos}`
    this.labels.elementosVisibles = `${this.nombreElementos} por página`;
  }

  protected seleccionarPagina(pagina: number) {
    this.paginaActual = pagina;
    const posicionActual = (this.paginaActual - 1);

    this.onPaginaSeleccionada();

    if (this.paginaActual != 1 &&
      this.paginaActual != this.paginas[this.paginas.length - 1]) {
      this.inicioVisible = posicionActual - 1;
      this.finVisible = posicionActual + 1;
    } else if (this.paginaActual == 1) {
      this.inicioVisible = 0;
      this.finVisible = this.paginas.length > 2 ? 2 : 1;
    } else if (this.paginaActual == this.obtenerUltimaPagina()) {
      this.inicioVisible = this.paginas.length > 2 ? posicionActual - 2 : 0;
      this.finVisible = posicionActual;
    }
  }

  protected moverPagina(derecha: boolean) {
    let posicion = this.paginaActual - 1;

    if (derecha) {
      posicion += 1;
    }
    else {
      posicion -= 1;
    }

    this.seleccionarPagina(this.paginas[posicion]);
  }

  protected obtenerUltimaPagina() {
    return this.paginas[(this.paginas.length - 1)];
  }

  protected onCantidadSeleccionada() {
    this.paginaActual = 1;
    this.inicioVisible = 0;
    this.finVisible = 2;

    this.cantidadSeleccionada.emit(this.cantidadVisible);
    this.onPaginaSeleccionada();
  }

  protected onPaginaSeleccionada() {
    this.paginaSeleccionada.emit(this.paginaActual);
  }

}