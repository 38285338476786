<input 
    class="input is-small is-uppercase"
    type="text"
    [placeholder]="placeholder" 
    [name]="name"
    [(ngModel)]="filtroTexto" 
    (input)="onInputChange($event)">

<span class="icon is-small is-left">
    <i class="fas fa-search"></i>
</span>