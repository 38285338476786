<div class="field is-horizontal">
    <div class="field-label is-normal">
        <label class="level-left">{{labelPersona}}</label>
    </div>
    <div class="field-body">

        <div class="field is-grouped is-grouped-multiline">

            <div class="control">
                <input 
                    class="input is-small"
                    type="text" 
                    placeholder="{{labels.numeroDocumento}}"
                    name="numeroDocumento"
                    [(ngModel)]="persona.numeroDocumento"
                    (blur)="consultarPersonaPorNumeroDocumento()">
            </div>

            <div class="control is-expanded">
                <div class="field has-addons has-addons-right">

                    <div class="control is-expanded">
                        <input 
                            class="input is-small"
                            type="text" 
                            placeholder="{{labels.nombreCompleto}}"
                            name="nombreCompleto"
                            [(ngModel)]="persona.nombres + ' ' + persona.apellidos"
                            disabled>
                    </div>

                    <div class="control">
            
                        <button 
                            class="button is-small landing addon-button"
                            (click)="abrirModalConsultarPersonas()">
                            <span class="icon is-small">
                                <i class="fas fa-search"></i>
                            </span>
                        </button>
        
                    </div>
        
                    <div class="control">
                    
                        <button 
                            class="button is-small landing addon-button"
                            (click)="abrirModalCrearPersona()">
                            <span class="icon is-small">
                                <i class="fas fa-plus"></i>
                            </span>
                        </button>
        
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>

<div [ngClass]="modalConsultarPersonasActivo ? 'modal is-active' : 'modal'">
    <div 
        class="modal-background"
        (click)="cerrarModalConsultarPersonas()"></div>
    <div class="modal-content modal-listado">
        <div class="box" >

            <app-personas
                #listadoPersonas
                [esBuscador]="true"
                (personaSeleccionada)="personaSeleccionadaEnListado($event)">
            </app-personas>

        </div>
    </div>
</div>

<div [ngClass]="modalCrearPersonaActivo ? 'modal is-active' : 'modal'">
    <div 
        class="modal-background"
        (click)="cerrarModalCrearPersona()"></div>
    <div class="modal-content modal-listado">
        <div class="box" >

            <app-persona
                [creacionPersona]="true"
                (personaCreada)="personaCreada($event)">
            </app-persona>

        </div>
    </div>
</div>