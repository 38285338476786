<section class="servicios">
    <h1>{{labels.servicios}}</h1>
    <div class="info columns residencial">
        <div class="column">
            <div class="contenedor-imagenes" *ngFor="let uso of servicioResidencial">
                <img [src]="uso.src" [className]="uso.clase">
            </div>
        </div>
        <div class="descripcion column">
            <h2>{{labels.residencial}}</h2>
            <p>{{labels.descripcionR}}</p>
            <button class="button landing-light">{{labels.boton}}</button>
        </div>
    </div>
    <div class="info columns comercial">
        <div class="descripcion column">
            <h2>{{labels.comercial}}</h2>
            <p>{{labels.descripcionC}}</p>
            <button class="button landing-light">{{labels.boton}}</button>
        </div>
        <div class="column">
            <div class="contenedor-imagenes" *ngFor="let uso of servicioComercial">
                <img [src]="uso.src" [className]="uso.clase">
            </div>
        </div>
    </div>
    <hr>
</section>