import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Persona } from 'src/app/models/persona';
import { Usuario } from 'src/app/models/usuario';
import { Utilidades } from 'src/app/models/utilidades';
import { UsuarioService } from 'src/app/services/usuario.service';
import { TxtPersonaComponent } from '../txt-persona/txt-persona.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class UsuariosComponent implements OnInit {

  public usuario: Usuario = new Usuario();
  public usuarios: Array<any> = new Array<any>();

  public altoDivTabla: number;
  @ViewChild('divForm')
  public divForm: ElementRef;
  @ViewChild('txtPersona')
  public txtPersona: TxtPersonaComponent;
  public contrasenna: string = '';
  public contrasennaConfirmada: string = '';
  public contrasennaValida: boolean = true;
  public verContrasenna: boolean = false;
  public verContrasennaConfirmada: boolean = false;
  @ViewChild('tipo')
  public tipo: ElementRef;
  @ViewChild('nombre')
  public nombre: ElementRef;
  public thTipo: number;
  public thNombre: number;

  public labels = {
    usuario: 'Usuario',
    nombre: 'Nombre',
    contrasenna: 'Contraseña',
    confirmar: 'Confirmar contraseña',
    persona: 'Persona',
    valida: '',
    iguales: ''
  };

  public columnas = {
    tipo: 'Tipo de usuario',
    nombre: 'Nombre',
    acciones: 'Acciones'
  }

  public botones = {
    eliminar: 'Eliminar',
    guardar: 'Guardar'
  }

  public mensajes = {
    usuarioCreado: 'El usuario se ha creado correctamente.',
    usuarioActualizado: 'El usuario se ha actualizado correctamente.',
    usuarioEliminado: 'El usuario se ha eliminado correctamente.',
    contrasennaActualizada: 'La contraseña se ha actualizado correctamente.',
    preguntaEliminar: '¿Está seguro de eliminar el usuario? Esta opción no se puede deshacer.',
  }

  constructor(
    private _usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.consultarUsuarios();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  public onResize() {
    this.setColumnWidth();

    if (this.divForm) {
      let altoDivForm: number = this.divForm.nativeElement.offsetHeight;
      let altoDivTabla = window.innerHeight - altoDivForm - 160;

      if (altoDivTabla != this.altoDivTabla) {
        this.altoDivTabla = altoDivTabla;
      }
    }
  }

  public guardar() {
    if (this.usuario.id == 0) {
      this.crear();
    } else {
      this.actualizar();
    }
  }

  private crear() {
    this._usuarioService.crear(this.usuario).subscribe(
      result => {
        if (result.resultado) {
          this.usuario.id = result.datos.id;
          this.consultarUsuarios();
          Utilidades.dialogSuccess(this.mensajes.usuarioCreado);
        }
      }
    );
  }

  private actualizar() {
    this._usuarioService.actualizar(this.usuario).subscribe(
      result => {
        if (result.resultado) {
          this.consultarUsuarios();
          Utilidades.dialogSuccess(this.mensajes.usuarioActualizado);
        }
      }
    );
  }

  public eliminar() {
    Utilidades.dialogPregunta('', this.mensajes.preguntaEliminar, 'Sí, eliminar').then(
      result => {
        if (result.isConfirmed) {
          this._usuarioService.eliminar(this.usuario.id).subscribe(
            result => {
              if (result.resultado) {
                this.consultarUsuarios();
                this.limpiar();
                Utilidades.dialogSuccess(this.mensajes.usuarioEliminado);
              }
            }
          );
        }
      });
  }

  public limpiar() {
    this.usuario = new Usuario();
    this.txtPersona.persona = new Persona();
    this.txtPersona.consultarPersonaPorNumeroDocumento();
    this.contrasenna = '';
    this.contrasennaConfirmada = '';
  }

  public consultarUsuarios() {
    this._usuarioService.consultarUsuarios().subscribe(
      result => {
        if (result.resultado) {
          this.usuarios = result.datos;
        }
      }
    );
  }

  public consultarPorId(id: number) {
    this._usuarioService.consultarPorId(id).subscribe(
      result => {
        if (result.resultado) {
          this.usuario = new Usuario();
          this.usuario.inicializar(result.datos);
        }
      }
    );

    this.contrasenna = '';
    this.contrasennaConfirmada = '';
  }

  public personaSeleccionada(persona) {
    this.usuario.persona = persona;
  }

  public tipoUsuarioSeleccionado(tipoUsuario) {
    this.usuario.tipo = tipoUsuario;
  }

  public setColumnWidth() {
    if (this.tipo) {
      this.thTipo = this.tipo.nativeElement.offsetWidth;
      this.thNombre = this.nombre.nativeElement.offsetWidth;
    }
  }

  public visibilidadContrasenna() {
    this.verContrasenna = !this.verContrasenna;
  }

  public visibilidadContrasennaConfirmada() {
    this.verContrasennaConfirmada = !this.verContrasennaConfirmada;
  }

  public validarContrasenna(contrasenna: string) {
    if (contrasenna.length === 0) {
      this.labels.valida = '';
    } else if (contrasenna.match(/^(?=.*[A-z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{10,}$/)) {
      this.contrasennaValida = true;
      this.labels.valida = '';
    } else {
      this.contrasennaValida = false;
      this.labels.valida = 'La contraseña debe tener al menos 10 caracteres, un número, una letra y un símbolo.';
    }

    this.actualizarContrasenna();
  }

  public validarContrasennasIguales() {
    this.validarContrasenna(this.contrasennaConfirmada);

    if (this.contrasennaConfirmada.length === 0) {
      this.labels.iguales = '';
    } else if (this.contrasenna === this.contrasennaConfirmada) {
      this.labels.iguales = '';
    } else {
      this.labels.iguales = 'Las contraseñas no coinciden';
    }

    this.actualizarContrasenna();
  }

  private actualizarContrasenna() {
    if ((this.contrasenna === this.contrasennaConfirmada) && this.contrasennaValida) {
      this.usuario.contrasenna = this.contrasennaConfirmada;
      this.labels.iguales = '';
    } else {
      this.usuario.contrasenna = '';
    }
  }

}