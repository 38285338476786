import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    private isLoadingSubject = new BehaviorSubject<boolean>(false);
    public isLoading$ = this.isLoadingSubject.asObservable();

    public show(): void {
        this.isLoadingSubject.next(true);
    }

    public hide(): void {
        this.isLoadingSubject.next(false);
        this.isLoadingSubject.complete();
    }

}