<div class="login">
    <a (click)="abrirModalLogin()">{{labels.cuenta}}</a>
    <span class="icon" (click)="abrirModalLogin()">
        <i class="fas fa-user"></i>
    </span>
</div>

<div class="modal" [ngClass]="modalLogin ? 'is-active' : ''">
    <div class="modal-background" (click)="cerrarModalLogin()"></div>
    <div class="modal-content modal-login">
        <div class="box">

            <div class="has-text-right">
                <a id="btn-cerrar-menu">
                    <span class="icon is-small has-text-dark" (click)="cerrarModalLogin()">
                        <i class="fas fa-times"></i>
                    </span>
                </a>
            </div>

            <div class="box">

                <h1 class="title has-text-centered">{{labels.mensajeLogin}}</h1>

                <form autocomplete="off">

                    <!-- Usuario -->

                    <div class="field">
                        <div class="control has-icons-left">

                            <input type="text" name="usuario" class="input" placeholder="{{labels.usuario}}"
                                [(ngModel)]="usuario" (keydown.enter)="login()">

                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                        </div>
                    </div>

                    <!-- FIN: Usuario -->

                    <!-- Contraseña -->

                    <div class="field">
                        <div class="control has-icons-left">

                            <input type="password" name="contrasenna" class="input" placeholder="{{labels.contrasenna}}"
                                [(ngModel)]="contrasenna" (keydown.enter)="login()">

                            <span class="icon is-small is-left">
                                <i class="fas fa-lock"></i>
                            </span>
                        </div>
                    </div>

                    <!-- FIN: Contraseña -->

                    <div class="field">
                        <span class="tag is-danger is-light" *ngIf="mensaje">
                            {{mensaje}}
                        </span>
                    </div>

                    <!-- Botón de login -->

                    <div class="field">
                        <button class="button landing is-fullwidth" (click)="login()">
                            {{botones.login}}
                        </button>
                    </div>

                    <!-- FIN: Botón de login -->

                </form>

            </div>

        </div>
    </div>
</div>