<section class="sucursales">
    <div class="row">
        <div *ngFor="let sucursal of sucursales" class="sucursal">
            <h2>{{ sucursal.nombre }}</h2>
            <img [src]="'https://appdecoraytransforma.co/demo/decora_transforma/documentos/' + sucursal.nombreImagen + '.png'"
                (click)="redirectToUrl(sucursal.ubicacionMaps)">
            <div class="info">
                <span>
                    <i class="fas fa-map-marker-alt"></i>
                    {{ sucursal.direccion }}
                </span>
            </div>
            <!-- <div class="info">
                <span>
                    <i class="fas fa-phone-alt"></i>
                    {{ sucursal.nombreImagen }}
                </span>
            </div> -->
        </div>
    </div>
</section>