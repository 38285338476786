import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Utilidades } from 'src/app/models/utilidades';
import { FacturaService } from 'src/app/services/factura.service';
import { PagoService } from 'src/app/services/pago.service';
import { PaginacionComponent } from '../paginacion/paginacion.component';
import { Factura } from 'src/app/models/factura';

@Component({
  selector: 'app-listado-facturas',
  templateUrl: './listado-facturas.component.html',
  styleUrls: ['./listado-facturas.component.css'],
  providers: [
    FacturaService
  ],
  host: {
    '(window:resize)': 'onResize($event)'
  },
})
export class ListadoFacturasComponent implements OnInit, AfterViewInit {

  public facturas: Array<any> = new Array<any>();
  public factura: Factura = new Factura();

  /**
   * Propiedades del diseño
   */

  @ViewChild('divForm')
  public divForm: ElementRef;
  public altoDivTabla: number;
  @Input()
  public componenteHijo: boolean = false;
  public filtroTexto: string;
  public fechaInicialFiltro: Date;
  public fechaFinalFiltro: Date;
  @ViewChild('paginacion')
  public paginacionComponent: PaginacionComponent;
  @ViewChild('consecutivo')
  public consecutivo: ElementRef;
  @ViewChild('nombreCliente')
  public nombreCliente: ElementRef;
  @ViewChild('valorTotal')
  public valorTotal: ElementRef;
  @ViewChild('pagada')
  public pagada: ElementRef;
  @ViewChild('remisionada')
  public remisionada: ElementRef;
  public thConsecutivo: number;
  public thNombreCliente: number;
  public thValorTotal: number;
  public thPagada: number;
  public thRemisionada: number;

  public labels = {
    buscar: 'Buscar...',
    facturas: 'Facturas'
  };

  public columnas = {
    numeroFactura: 'N° factura',
    nombreCliente: 'Nombre / Razón social',
    valorTotal: 'Valor total',
    pagada: 'Pagada',
    remisionada: 'Remisionada',
    acciones: 'Acciones'
  };

  public botones = {
    nuevo: 'Crear factura',
    excel: 'Exportar a excel',
    excelPagos: 'Generar informe de pagos'
  }

  constructor(
    private _facturaService: FacturaService,
    private _pagoService: PagoService
  ) { }

  ngOnInit(): void {
    this.consultarListado();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  public onResize() {
    this.setColumnWidth();

    if (this.componenteHijo) {
      return;
    }

    if (this.divForm) {
      let altoDivForm: number = this.divForm.nativeElement.offsetHeight;
      let altoDivTabla = window.innerHeight - altoDivForm - 160;

      if (altoDivTabla != this.altoDivTabla) {
        this.altoDivTabla = altoDivTabla;
      }
    }
  }

  public consultarListado() {

    this._facturaService.consultarListado().subscribe(
      result => {

        if (result.resultado) {

          this.paginacionComponent.cantidadTotalElementos = result.datos.cantidad_facturas;
          this._facturaService.consultarListadoPorPaginacion(this.factura.id, this.paginacionComponent.cantidadVisible, this.paginacionComponent.paginaActual).subscribe(
            result => {

              this.facturas = new Array<any>();

              result.datos.facturas.forEach(item => {
                let factura: any = new Object();

                factura.id = item.id;
                factura.consecutivo = item.consecutivo;
                factura.fecha = item.fecha;
                factura.nombreCliente = item.nombre_cliente.toUpperCase();
                factura.numeroDocumentoCliente = item.numero_documento_cliente;
                factura.nombreVendedor = item.nombre_completo_vendedor;
                factura.pagada = item.pagada;
                factura.remisionada = item.remisionada
                factura.valorTotal = item.valor_total;

                this.facturas.push(factura);
              });

              this.paginacionComponent.cantidadElementos = this.facturas.length;
              this.paginacionComponent.establecerPaginas();

              this.fechaInicialFiltro = result.datos.fecha_inicial;
              this.fechaFinalFiltro = result.datos.fecha_final;
              this.filtroTexto = '';
            }
          )
        }
      }
    );
  }

  public exportarExcel() {
    this._facturaService.consultarInformeCsv(this.fechaInicialFiltro, this.fechaFinalFiltro, this.filtroTexto).subscribe(
      result => {
        Utilidades.dialogImprimir(result.datos.ruta);
      }
    );
  }

  public exportarPagosExcel() {
    this._pagoService.consultarInformeCSV(this.fechaInicialFiltro, this.fechaFinalFiltro).subscribe(
      result => {
        Utilidades.dialogImprimir(result.datos.ruta);
      }
    );
  }

  public setColumnWidth() {
    if (this.consecutivo) {
      this.thConsecutivo = this.consecutivo.nativeElement.offsetWidth;
      this.thNombreCliente = this.nombreCliente.nativeElement.offsetWidth;
      this.thValorTotal = this.valorTotal.nativeElement.offsetWidth;
      this.thPagada = this.pagada.nativeElement.offsetWidth;
      this.thRemisionada = this.remisionada.nativeElement.offsetWidth;
    }
  }

  public onFilterTextChanged(filterText: string) {
    this.filtroTexto = filterText;
  }

}
