import { Component, ElementRef, HostListener, Renderer2, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from 'src/app/components/login/login.component';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent {

  public mostrarBotonWhatsApp: boolean = true;
  public mostrarIndicadores: boolean = true;
  public menuTransparente: boolean = true;
  public menuModal: boolean = false;
  public media: number = 0;
  public login: LoginComponent;
  @Input()
  public carruselId: string;
  @Input()
  public nosotros: boolean = false;
  @ViewChild('boton')
  public boton: ElementRef;
  @ViewChild('opcionesContacto')
  public opcionesContacto: ElementRef;

  public labels = {
    home: 'HOME',
    nosotros: 'NOSOTROS',
    servicios: 'SERVICIOS',
    tienda: 'TIENDΛ',
    buscar: 'BUSCΛR',
    cuenta: 'CUENTΛ',
    carrito: 'CΛRRITO',
    saludo: '¡Hola!',
    asesor: 'Selecciona el asesor con el que te deseas comunicar',
    ventas: 'Λsesor de ventas al detal',
    compras: 'Λsesor de ventas al por mayor'
  }

  constructor(
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private loaderService: LoaderService
  ) {
    window.addEventListener('scroll', () => {
      this.cerrarOpcionesContacto();
    });

    document.addEventListener('mousedown', () => {
      this.cerrarOpcionesContacto();
    });
  }

  ngOnInit(): void {
    if (!sessionStorage.getItem('load')) {
      this.loaderService.show();

      setTimeout(() => {
        this.loaderService.hide();
        sessionStorage.setItem('load', '1');
      }, 2000);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const categoriasComponent = document.getElementById('categorias');
    if (!categoriasComponent) return;
    const offset = categoriasComponent.getBoundingClientRect().top;
    this.media = window.innerWidth;

    if (this.media >= 1280 || this.media <= 768) {
      this.menuTransparente = offset > 110;
    } else if (this.media > 768 && this.media < 1280) {
      this.menuTransparente = offset > 160;
    }
    this.cerrarMenu();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.media = window.innerWidth;
    if (window.innerWidth > 768) {
      this.cerrarMenu();
    }
  }

  public navigateTo(route: string) {
    this.router.navigate([route]).then(() => {
      window.scrollTo(0, 0);
    });

    this.cerrarMenu();
  }

  public mostrarOpcionesContacto() {
    const opcionesContacto = this.el.nativeElement.querySelector('.opciones-contacto');
    this.renderer.setStyle(opcionesContacto, 'display', 'block');
  }

  public cerrarOpcionesContacto() {
    const opcionesContacto = this.el.nativeElement.querySelector('.opciones-contacto');
    this.renderer.setStyle(opcionesContacto, 'display', 'none');
  }

  public abrirMenu() {
    this.menuModal = true;
  }

  public cerrarMenu() {
    this.menuModal = false;
  }

}