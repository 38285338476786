<div>

    <form autocomplete="off" spellcheck="false">

        <!-- Fecha -->

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">{{labels.fecha}}</label>
            </div>
            <div class="field-body">
                
                <div class="control">
                    <input 
                        class="input is-small"
                        type="date" 
                        name="fecha"
                        [(ngModel)]="pago.fecha">
                </div>

            </div>
        </div>

        <!-- FIN: Fecha -->

        <!-- Valor -->

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">{{labels.valor}}</label>
            </div>
            <div class="field-body">
                
                <div class="control">
                    <input 
                        class="input is-small"
                        type="number" 
                        name="valor"
                        [(ngModel)]="pago.valor">
                </div>

            </div>
        </div>

        <!-- FIN: Valor -->

        <!-- Medio de pago -->

        <div class="field is-horizontal">

            <div class="field-label">
                <label class="level-left">{{labels.medioPago}}</label>
            </div>

            <div class="field-body">

                <div class="control">

                    <div class="select is-small">
                        <app-select-medios-pago
                            #selectMedioPago
                            (medioPagoSeleccionado)="medioPagoSeleccionado($event)"></app-select-medios-pago>
                    </div>

                </div>

            </div>

        </div>

        <!-- FIN: Medio de pago -->

        <!-- Notas -->

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">{{labels.notas}}</label>
            </div>
            <div class="field-body">
                
                <input
                    type="text" 
                    class="input is-small"
                    name="notas"
                    [(ngModel)]="pago.notas">

            </div>
        </div>

        <!-- FIN: Notas -->

    </form>

    <br>

    <div class="columns">

        <div class="column">

            <div class="field is-grouped">
                <div class="control">
                    <span class="tag is-light is-outlined">
                        <p class="has-text-dark">
                            <strong>Pagado: </strong>{{pagado}} $COP
                        </p>
                    </span>
                </div>

                <div class="control">
                    <span class="tag is-light is-danger">
                        <p class="has-text-dark">
                            <strong>Adeudado: </strong>{{adeudado}} $COP
                        </p>
                    </span>
                </div>
            </div>

        </div>
        <div class="column">
            <div class="buttons is-right">
        
                <button 
                    class="button is-small is-outlined"
                    (click)="guardar()">
                    {{botones.guardar}}
                </button>
        
                <button 
                    class="button is-small"
                    (click)="limpiarCampos()">
                    <span class="icon is-small has-text-primary">
                        <i class="fas fa-eraser"></i>
                    </span>
                </button>
        
            </div>
        </div>
        
    </div>

    <div class="div-tabla is-size-7">

        <table class="table is-fullwidth is-bordered">
            <thead>
                <tr>
                    <th>{{columnas.fecha}}</th>
                    <th>{{columnas.valor}}</th>
                    <th width="5%">{{columnas.acciones}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pago of factura.pagos; let i = index">
                    <td (click)="llenarPago(i)">{{pago.fecha}}</td>
                    <td (click)="llenarPago(i)">{{pago.valor}}</td>
                    <td>
                        <div class="buttons is-centered">
                            <button 
                                class="button is-small is-danger"
                                (click)="eliminar(i)">
                                <span class="icon is-small">
                                    <i class="fas fa-trash-alt"></i>
                                </span>
                            </button>

                            <!-- <app-btn-confirm>
                                
                            </app-btn-confirm> -->

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

</div>