import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './components/component/app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PersonasComponent } from './components/personas/personas.component';
import { SelectDepartamentosComponent } from './components/select-departamentos/select-departamentos.component';
import { SelectMunicipiosComponent } from './components/select-municipios/select-municipios.component';
import { PersonaComponent } from './components/persona/persona.component';
import { SelectTiposPersonaComponent } from './components/select-tipos-persona/select-tipos-persona.component';
import { SelectTiposDocumentoComponent } from './components/select-tipos-documento/select-tipos-documento.component';
import { ProductosComponent } from './components/productos/productos.component';
import { ListadoFacturasComponent } from './components/listado-facturas/listado-facturas.component';
import { FacturaComponent } from './components/factura/factura.component';
import { TxtPersonaComponent } from './components/txt-persona/txt-persona.component';
import { SelectProductosComponent } from './components/select-productos/select-productos.component';
import { AppRoutingModule } from './app-routing.module';
import { CotizacionComponent } from './components/cotizacion/cotizacion.component';
import { PrestamoComponent } from './components/prestamo/prestamo.component';
import { ListadoCotizacionesComponent } from './components/listado-cotizaciones/listado-cotizaciones.component';
import { ListadoPrestamosComponent } from './components/listado-prestamos/listado-prestamos.component';
import { FiltroFacturasPipe } from './pipes/filtro-facturas.pipe';
import { FiltroPersonasPipe } from './pipes/filtro-personas.pipe';
import { FiltroCotizacionesPipe } from './pipes/filtro-cotizaciones.pipe';
import { FiltroPrestamosPipe } from './pipes/filtro-prestamos.pipe';
import { FiltroProductosPipe } from './pipes/filtro-productos.pipe';
import { DetalleCotizacionComponent } from './components/detalle-cotizacion/detalle-cotizacion.component';
import { RemisionComponent } from './components/remision/remision.component';
import { ProductoService } from './services/producto.service';
import { PagosComponent } from './components/pagos/pagos.component';
import { BtnConfirmComponent } from './components/btn-eliminar/btn-confirm.component';
import { ComprasComponent } from './components/compras/compras.component';
import { SelectMediosPagoComponent } from './components/select-medios-pago/select-medios-pago.component';
import { CarruselComponent } from './landing-page/components/carrusel/carrusel.component';
import { ContactoComponent } from './landing-page/components/contacto/contacto.component';
import { FooterComponent } from './landing-page/components/footer/footer.component';
import { MenuComponent } from './landing-page/components/menu/menu.component';
import { NosotrosComponent } from './landing-page/components/nosotros/nosotros.component';
import { SucursalesComponent } from './landing-page/components/sucursales/sucursales.component';
import { ServiciosComponent } from './landing-page/components/servicios/servicios.component';
import { PaginacionComponent } from './components/paginacion/paginacion.component';
import { BannerComponent } from './landing-page/components/banner/banner.component';
import { SucursalComponent } from './components/sucursal/sucursal.component';
import { LandingPageComponent } from './landing-page/landing-page/landing-page.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InterceptorService } from './services/interceptor.service';
import { InputTimerComponent } from './components/input-timer/input-timer.component';
import { LoaderModule } from './landing-page/components/loader/loader.module';
import { CategoriasProductosComponent } from './landing-page/components/categorias-productos/categorias-productos.component';
import * as Hammer from 'hammerjs';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { SelectTiposUsuarioComponent } from './components/select-tipos-usuario/select-tipos-usuario.component';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const touch = new Hammer(element, {
      touchAction: 'pan-y', 
    });

    touch.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
    touch.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL });

    return touch;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    PersonasComponent,
    SelectDepartamentosComponent,
    SelectMunicipiosComponent,
    PersonaComponent,
    SelectTiposPersonaComponent,
    SelectTiposDocumentoComponent,
    ProductosComponent,
    ListadoFacturasComponent,
    FacturaComponent,
    TxtPersonaComponent,
    SelectProductosComponent,
    CotizacionComponent,
    PrestamoComponent,
    ListadoCotizacionesComponent,
    ListadoPrestamosComponent,
    FiltroFacturasPipe,
    FiltroPersonasPipe,
    FiltroCotizacionesPipe,
    FiltroPrestamosPipe,
    FiltroProductosPipe,
    DetalleCotizacionComponent,
    RemisionComponent,
    PagosComponent,
    BtnConfirmComponent,
    ComprasComponent,
    SelectMediosPagoComponent,
    CarruselComponent,
    ContactoComponent,
    FooterComponent,
    MenuComponent,
    NosotrosComponent,
    SucursalesComponent,
    ServiciosComponent,
    PaginacionComponent,
    BannerComponent,
    SucursalComponent,
    LandingPageComponent,
    InputTimerComponent,
    CategoriasProductosComponent,
    UsuariosComponent,
    SelectTiposUsuarioComponent,
    ConfiguracionesComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AngularCropperjsModule,
    NgxSpinnerModule,
    LoaderModule,
    HammerModule
  ],
  providers: [
    ProductoService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }