export class TipoDocumento {

    constructor(
        public id: number = 0,
        public _nombre: string = ''
    ){ }

    /**
     * Encapsulamiento
     */

     get nombre(): string {
        return this._nombre.toUpperCase();
    }

    set nombre(value: string) {
        this._nombre = value.toString().toUpperCase();
    }

    /**
     * Métodos
     */

    public inicializar(datos){
        if(!!datos){
            this.id = !!datos.id ? datos.id : this.id;
            this.nombre = !!datos.nombre ? datos.nombre: this.nombre;
        }
    }

}