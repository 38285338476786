<footer class="footer">
    <div class="columns">
        <div class="column">
            <ul class="logo">
                <li><img src="./assets/logos/logoFooter.png" /></li>
            </ul>
        </div>
        <div class="column enlaces">
            <ul>
                <h3>{{labels.enlaces}}</h3>
                <li>
                    <a href="#">{{labels.busqueda}}</a>
                </li>
                <li>
                    <a href="#">{{labels.reversion}}</a>
                </li>
                <li>
                    <a href="#">{{labels.politicaEnvio}}</a>
                </li>
                <li>
                    <a href="#">{{labels.politicaReembolso}}</a>
                </li>
                <li>
                    <a href="#">{{labels.terminos}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="copy">
        <ul>
            <li>
                <p>&copy; {{labels.copy}} {{ anoActual }}</p>
            </li>
            <li>
                <p>{{labels.derechos}}</p>
            </li>
        </ul>
    </div>
</footer>