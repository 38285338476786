export class Empresa {

    constructor(
        public id: number = 0,
        public _nombre: string = ''
    ) { }

    /**
     * Encapsulamiento
     */

    get nombre(): string{
        return this._nombre.toUpperCase();
    }

    set nombre(value: string) {
        this._nombre = value.toString().toUpperCase();
    }

    /**
     * Métodos
     */

    public inicializar(datos: any): void{
        if(!!datos){
            this.id = !!datos.id ? datos.id : 0;
            this.nombre = !!datos.nombre ? datos.nombre : '';
        }
    }

}