import { Empresa } from "./empresa";
import { Municipio } from "./municipio";

export class Sucursal {

    constructor(
        public id: number = 0,
        public empresa: Empresa = new Empresa(),
        public municipio: Municipio = new Municipio(),
        public nombre: string = '',
        public direccion: string = '',
        public ubicacionMaps: string = '',
        public nombreImagen: string = '',
        public habilitada: boolean = false,
        public rutaImagen: string = ''
    ) { }

    /**
     * Métodos
     */

    public inicializar(datos: any): void{
        if(!!datos){
            this.id = !!datos.id ? datos.id : this.id;
            this.nombre = !!datos.nombre ? datos.nombre : this.nombre;
            this.direccion = !!datos.direccion ? datos.direccion : this.direccion;
            this.ubicacionMaps = !!datos.ubicacion_maps ? datos.ubicacion_maps : this.ubicacionMaps;
            this.nombreImagen = !!datos.nombre_imagen ? datos.nombre_imagen : this.nombreImagen;
            this.habilitada = !!datos.habilitada ? datos.habilitada == 1 : this.habilitada;
            this.rutaImagen = !!datos.ruta_imagen ? datos.ruta_imagen : this.rutaImagen;
            
            this.empresa.inicializar(datos.empresa);
            this.municipio.inicializar(datos.municipio);
        }
    }

}