<header class="header">
    <nav class="navbar menu">
        <div class="navbar-brand">
            <a class="navbar-item" (click)="navigateTo('/')">
                <img class="logo" src="./assets/logos/logoDashboard.svg">
            </a>
            <a role="button" 
                class="navbar-burger burger" 
                aria-label="menu" 
                aria-expanded="false" 
                (click)="activarMenuBurger()">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div class="navbar-menu"
                [ngClass]="menuActivo ? 'is-active' : ''">
            <div class="navbar-end">
                <div [ngClass]="menuActivo ? 'navbar-item has-dropdown is-active' : 'navbar-item has-dropdown'"
                    (mouseover)="establecerEstiloMenuUsuario($event)"
                    (mouseout)="establecerEstiloMenuUsuario($event)">
                    <a class="navbar-link">
                        {{nombreUsuario}}
                    </a>
            
                    <div class="navbar-dropdown is-right is-boxed">
                        <a 
                            class="navbar-item"
                            (click)="cerrarSesion()">
                            {{labels.cerrarSesion}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>

<div class="has-text-centered">
    <a id="btn-ver-menu" 
        class="button landing has-icons-left"
        [@ocultarMenu]="btnVerMenuLateralVisible ? 'visible' : 'oculto'"
        (@ocultarMenu.done)="establecerEstadoMenuLateral()"
        (click)="ocultarBtnVerMenu()">
        <span class="icon">
            <i class="fas fa-bars"></i>
        </span>
        
    </a>
</div>

<aside #menuLateral
    id="menu-lateral" 
    [@ocultarMenu]="menuLateralVisible ? 'visible' : 'oculto'"
    (@ocultarMenu.done)="establecerEstadoBtnVerMenu()">
    
    <div class="has-text-right">
        <a id="btn-cerrar-menu">
            <span 
                class="icon is-small has-text-dark"
                (click)="ocultarMenuLateral()">
                <i class="fas fa-times"></i>
            </span>
        </a>
    </div>

    <ul class="menu-list-item">
        <li 
            class="menu-item"
            *ngFor="let opcion of opcionesMenu">

            <a [routerLink]="[opcion.route]" 
                [routerLinkActive]="['menu-activo']">
                <i [ngClass]="[opcion.icono]"></i>
                {{ opcion.nombre }}
            </a>

        </li>

    </ul>    
</aside>

<!-- [ngClass]="menuLateralVisible ? 'seccion-componentes menu-visible' : 'seccion-componentes menu-oculto'" -->
<section #divContenedor 
        class="seccion-componentes"
        [@establecerTamannoContenedor]="menuLateralVisible ? 'menu-visible' : 'menu-oculto'">
    <div>
        
        <router-outlet></router-outlet>

    </div>
</section>