<section class="contacto">
    <h1>{{labels.contacto}}</h1>
    <div class="columns">
        <div class="column is-5 formulario">
            <!-- <div class="head">
                <p>{{labels.formulario}}</p>
            </div> -->
            <form autocomplete="off" class="form" (ngSubmit)="onSubmit()" #contactForm="ngForm">
                <div class="field">
                    <div class="control">
                        <input class="input" type="text" placeholder="{{labels.nombre}}" [(ngModel)]="contacto.nombre"
                            name="nombre">
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <input class="input" type="text" placeholder="{{labels.apellido}}"
                            [(ngModel)]="contacto.apellido" name="apellido">
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <input class="input" type="email" placeholder="{{labels.email}}" [(ngModel)]="contacto.email"
                            name="email">
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <input class="input" type="text" placeholder="{{labels.telefono}}"
                            [(ngModel)]="contacto.telefono" name="telefono">
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <textarea class="textarea" rows="3" placeholder="{{labels.mensaje}}"
                            [(ngModel)]="contacto.mensaje" name="mensaje">
                </textarea>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox"> {{labels.suscripcion}}
                        </label>
                    </div>
                </div>

                <div class="field">
                    <div class="control">
                        <label class="checkbox">
                            <input type="checkbox">
                            {{labels.aceptacion}} <a href="#">{{labels.politica}}</a>
                        </label>
                    </div>
                </div>

                <div class="field is-grouped">
                    <div class="control">
                        <button type="submit" class="button landing-light">{{labels.enviar}}</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="column is-6 sucursales">
            <app-sucursales></app-sucursales>
        </div>
    </div>
    <hr>
</section>