import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Utilidades } from "../models/utilidades";
import { Configuracion } from "../models/configuracion";

@Injectable({ providedIn: 'root' })
export class ConfiguracionService {

    private url: string = environment.url + 'Configuracion';
    private tokenWeb: string = environment.tokenWeb;

    constructor(
        private _http: HttpClient
    ) { }

    public actualizar(configuracion: Configuracion): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(configuracion);
        const data = {
            'titulo_banner': configuracion.tituloBanner,
            'descripcion_banner': configuracion.descripcionBanner,
            'milisegundo_banner': '',
            'nombre_imagen_equipo': configuracion.nombreImagenEquipo,
            'sobre_nosotros_informacion': configuracion.sobreNosotrosInformacion,
            'mision': configuracion.mision,
            'vision': configuracion.vision
        };

        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'actualizar').
            set('id_tipo_usuario', 4).
            set('data', JSON.stringify(data));

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarPorTipo(): Observable<any> {
        const parametros: HttpParams = new HttpParams().
            set('token', this.tokenWeb).
            set('solicitud', 'consultar_por_tipo');

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public subirImagen(image: File): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const formData = new FormData();

        formData.append('token', usuario.token);
        formData.append('imagen', image);
        formData.append('solicitud', 'subir_imagen');

        return this._http.post(this.url, formData);
    }

}