import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Factura } from 'src/app/models/factura';
import { Pago } from 'src/app/models/pago';
import { Utilidades } from 'src/app/models/utilidades';
import { PagoService } from 'src/app/services/pago.service';
import { SelectMediosPagoComponent } from '../select-medios-pago/select-medios-pago.component';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {

  public pago: Pago = new Pago();
  public pagoTemporal: Pago = new Pago();
  @Input()
  public factura: Factura;
  private _pagado: number = 0; 
  private _adeudado: number = 0; 

  @ViewChild('selectMedioPago')
  protected selectMedioPago: SelectMediosPagoComponent;

  public get pagado(): string {
    return Utilidades.formatearDecimal(this._pagado);
  }
  public get adeudado(): string {
      return Utilidades.formatearDecimal(this._adeudado);
  }

  /**
   * Diseño
   */

  public labels = {
    fecha: 'Fecha',
    valor: 'Valor',
    notas: 'Notas',
    medioPago: 'Medio de pago'
  }

  public columnas = {
    fecha: 'Fecha',
    valor: 'Valor',
    acciones: 'Acciones'
  }

  public botones = {
    guardar: 'Guardar',
    eliminar: 'Eliminar'
  }

  constructor(
    private _pagoService: PagoService
  ) { }

  ngOnInit(): void {
    
  }

  public calcularTotales() {
    this._pagado = this.factura.pagos.reduce<number>((total, pago) => {
      return total + pago.valor;
    }, 0);

    this._adeudado = this.factura.valorTotal - this._pagado;
  }

  /**
   * Establece el pago con valores vacíos o 0
   * @param guardado Determina si el pago ha sido guardado para no cargar el pago temporal
   */
  public limpiarCampos(guardado: boolean = false) {
    if(this.pago.id != 0 && guardado == false) {
      this.factura.pagos.push(this.pagoTemporal);
    }

    this.pago = Object.assign({}, new Pago());
    this.pagoTemporal = Object.assign({}, new Pago());
    this.calcularTotales();
  }

  public llenarPago(indice: number) {
    if(this.pago.id != 0) {
      this.factura.pagos.push(this.pagoTemporal);
    }

    this.pagoTemporal = Object.assign({}, this.factura.pagos[indice]);
    this.pago = this.factura.pagos[indice];
    this.factura.pagos.splice(indice, 1);
    this.calcularTotales();

    this.selectMedioPago.setMedioPago(this.pago.medioPago);
  }

  public guardar() {
    if(this._adeudado > 0 && 
      this.pago.valor > 0 &&
      this.pago.valor <= this._adeudado){
        if(this.pago.id == 0) {
          this.crear();
        } else {
          this.actualizar();
        }
    }
  }

  public crear() {
    this._pagoService.crear(this.pago, this.factura.id).subscribe(
      result => {
        if(result.resultado) {
          this.pago.id = result.datos.id;
          this.factura.pagos.push(this.pago);
          this.calcularTotales();
          this.limpiarCampos(true);
        }
      }
    );
  }

  public actualizar() {
    this._pagoService.actualizar(this.pago).subscribe(
      result => {
        this.factura.pagos.push(this.pago);
        this.calcularTotales();
        this.limpiarCampos(true);
      }
    );
  }

  public eliminar(indice: number) {
    this._pagoService.eliminar(this.factura.pagos[indice]).subscribe(
      result => {
        if(result.resultado) {
          this.factura.pagos.splice(indice, 1);
          this.calcularTotales();
        }
      }
    );
  }

  public medioPagoSeleccionado(medioPago) {
    this.pago.medioPago = medioPago;
  }

}
