import { Component, OnInit } from '@angular/core';
import { Sucursal } from 'src/app/models/sucursal';
import { SucursalService } from 'src/app/services/sucursal.service';

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.css']
})
export class SucursalesComponent implements OnInit {

  public sucursales: any[] = [];

  constructor(
    private _sucursalService: SucursalService
  ) {
  }

  ngOnInit(): void {
    window.addEventListener("scroll", this.parallax.bind(this));
    this.consultarListado();
  }

  public parallax() {
    const parallax = document.querySelectorAll<HTMLElement>(".sucursal");

    for (let i = 0; i < parallax.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = parallax[i].getBoundingClientRect().top;

      if (elementTop < windowHeight - 100) {
        parallax[i].classList.add("active");
      }
    }
  }

  public redirectToUrl(url: string): void {
    window.open(url, "_blank")
  }

  public consultarListado() {
    this.sucursales = [];

    this._sucursalService.consultarListadoWeb().subscribe(
      result => {
        if (result.resultado) {
          result.datos.forEach(element => {
            const sucursal: Sucursal = new Sucursal();
            sucursal.inicializar(element);

            this.sucursales.push(sucursal);
          });
        }
      }
    );
  }

}