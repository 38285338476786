<div class="landing">
    <app-menu [nosotros]="true"></app-menu>

    <section class="nosotros">
        <div class="info">
            <h1>{{labels.nosotros}}</h1>
        </div>
        <div class="columns">
            <div class="column is-5">
                <img src="./assets/equipo.png" alt="..." />
            </div>
            <div class="column is-7 text">
                <p
                    [innerHTML]="expandir ? configuracion.sobreNosotrosInformacion + labels.mision + configuracion.mision + labels.vision + configuracion.vision : configuracion.sobreNosotrosInformacion">
                </p>
                <div class="boton">
                    <button class="button landing-light" (click)="boton()">
                        {{ expandir ? "Leer menos" : "Leer más" }}
                    </button>
                </div>
            </div>
        </div>
        <br>
        <hr>
        <app-contacto></app-contacto>
    </section>

    <app-footer></app-footer>
</div>