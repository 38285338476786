import { Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Configuracion } from 'src/app/models/configuracion';
import { ConfiguracionService } from 'src/app/services/configuracion.service';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.css']
})
export class NosotrosComponent {

  public configuracion: Configuracion = new Configuracion();

  public expandir: boolean = false;

  public labels = {
    nosotros: '¿Quiénes somos?',
    mision: '<br><br><h2>Misión</h2>',
    vision: '<br><br><h2>Visión</h2>'
  }

  constructor(
    private loaderService: LoaderService,
    private _configuracionService: ConfiguracionService
  ) { }

  ngOnInit(): void {
    if (!sessionStorage.getItem('load')) {
      this.loaderService.show();

      setTimeout(() => {
        this.loaderService.hide();
        sessionStorage.setItem('load', '1');
      }, 2000);
    }

    this.consultarPorTipo();
  }

  public boton() {
    this.expandir = !this.expandir;
  }

  public consultarPorTipo() {
    this._configuracionService.consultarPorTipo().subscribe(
      result => {
        this.configuracion = new Configuracion();
        this.configuracion.inicializar(result);
      }
    );
  }
  
}