<div #divForm class="form-filtros">

    <div class="field">

        <div class="control is-expanded has-icons-left">

            <app-input-timer [placeholder]="labels.buscar" [name]="filtro"
                (textoCompleto)="onFilterTextChanged($event)">
            </app-input-timer>

        </div>

    </div>

</div>

<div #divTabla class="div-tabla is-size-7" [style.height.px]="altoTabla">

    <div class="tabla-encabezado">
        <table class="table is-bordered">

            <thead>
                <tr>
                    <th #numeroDocumento [style.width]="!esBuscador ? '15%' : '20%'">{{columnas.numeroDocumento}}</th>
                    <th #nombreCompleto width="60%">{{columnas.nombreCompleto}}</th>
                    <th #tipoPersona [style.width]="!esBuscador ? '15%' : '20%'">{{columnas.tipoPersona}}</th>
                    <th *ngIf="!esBuscador" width="10%">{{columnas.acciones}}</th>
                </tr>
            </thead>

        </table>
    </div>

    <div #divCuerpoTabla class="div-cuerpo-tabla">
        <table class="table is-bordered is-fullwidth">

            <tbody>
                <tr *ngFor="let persona of personas | filtroPersonas: [ filtro ]"
                    (click)="onPersonaSeleccionada(persona.id)">
                    <td [style.width.px]="thNumeroDocumento">{{persona.numeroDocumento}}</td>
                    <td [style.width.px]="thNombreCompleto">{{persona.nombreCompleto}}</td>
                    <td [style.width.px]="!esBuscador ? thTipoPersona : 'auto'">{{persona.tipo}}</td>
                    <td *ngIf="!esBuscador">

                        <div class="buttons is-centered">
                            <button (click)="onPersonaSeleccionada(persona.id)" class="button is-small landing">
                                <span class="icon is-small">
                                    <i class="fas fa-user-edit"></i>
                                </span>
                            </button>
                        </div>

                    </td>
                </tr>
            </tbody>

        </table>

    </div>

    <app-paginacion #paginacion [nombreElementos]="labels.personas" (paginaSeleccionada)="consultarListado()"
        (cantidadSeleccionada)="consultarListado()">
    </app-paginacion>

</div>