import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Utilidades } from "../models/utilidades";

@Injectable({
    providedIn: 'root'
})
export class MedioPagoService {

    private url: string = environment.url;

    constructor(
        private _http: HttpClient
    ){}

    public consultarListado(): Observable<any> {
        const token = Utilidades.obtenerUsuario().token;
        const url = this.url + 'MedioPago';

        const parametros: HttpParams = new HttpParams().
        set('token', token);
        
        return this._http.get(url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

}