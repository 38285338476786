import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  public servicioResidencial: any[] = [];
  public servicioComercial: any[] = [];

  public labels = {
    servicios: 'Servicios',
    residencial: 'Residencial',
    descripcionR: 'Somos muy conscientes de que una vivienda es un espacio muy personal en el que deben primar los gustos y prioridades de las personas que las habitan. Por este motivo, trabajamos en contacto directo con el cliente en todo el proceso de diseño. Valorando las mejores opciones que el espacio ofrezca, teniendo siempre en cuenta los mejores acabados y soluciones para crear una vivienda cómoda, funcional y a la altura de las expectativas de nuestros clientes.',
    comercial: 'Comercial',
    descripcionC: 'Creamos o transformamos espacios comerciales por medio de un proceso riguroso de diseño y ejecución de obras de diseño interior para tiendas, oficinas o cualquier proyecto corporativo. Analizamos cada necesidad y, con base en las perspectivas corporativas de nuestros clientes, desarrollamos diseños únicos llenos de exclusividad y vanguardismo.',
    boton: 'Ver más'
  }

  constructor() {
    this.servicioResidencial.push({
      src: './assets/servicios/residencial.png',
      clase: 'imagen1'
    });
    this.servicioResidencial.push({
      src: './assets/servicios/residencial02.png',
      clase: 'imagen2'
    });

    this.servicioComercial.push({
      src: './assets/servicios/comercial.png',
      clase: 'imagen1'
    });
    this.servicioComercial.push({
      src: './assets/servicios/comercial02.png',
      clase: 'imagen2'
    });
  }

  ngOnInit(): void {
    window.addEventListener("scroll", this.parallax.bind(this));
  }

  public parallax() {
    const parallax1 = document.querySelectorAll<HTMLElement>(".imagen1");
    const parallax2 = document.querySelectorAll<HTMLElement>(".imagen2");

    for (let i = 0; i < parallax1.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop1 = parallax1[i].getBoundingClientRect().top;
      const elementTop2 = parallax2[i].getBoundingClientRect().top;

      if (elementTop1 < windowHeight - 100 && elementTop2 < windowHeight - 50) {
        parallax1[i].classList.add("active");
        parallax2[i].classList.add("active");
      }
    }
  }

}