import { Producto } from "./producto";

export class Compra {

    constructor(
        public id: number = 0,
        public fecha: string = new Date().toISOString().split("T")[0],
        public esCantidadInterna: boolean = false,
        public cantidad: number = 0
    ) { }

    public inicializar(datos) {
        if(!!datos) {
            this.id = !!datos.id ? datos.id : this.id;
            this.fecha = !!datos.fecha ? datos.fecha : this.fecha;
            this.esCantidadInterna = Boolean(!!datos.es_cantidad_interna ? datos.es_cantidad_interna : this.esCantidadInterna);
            this.cantidad = !!datos.cantidad ? datos.cantidad : this.cantidad;
        }
    }

}