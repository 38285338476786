import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Configuracion } from 'src/app/models/configuracion';
import { ConfiguracionService } from 'src/app/services/configuracion.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  public configuracion: Configuracion = new Configuracion();

  public top: string;
  public recorte: number = 15;

  constructor(
    private el: ElementRef,
    private _configuracionService: ConfiguracionService
  ) { }

  ngOnInit(): void {
    this.reiniciarAnimacion();
    this.actualizarTop(window.innerWidth);
    this.actualizarMedidas(window.innerWidth);
    this.consultarPorTipo();
  }

  ngAfterViewInit(): void {
    this.reiniciarAnimacion();
  }

  private reiniciarAnimacion(): void {
    const texto = this.el.nativeElement.querySelector('.texto');

    setTimeout(() => {
      texto.style.animation = 'none';
      void texto.offsetWidth;
      texto.style.animation = null;

      this.reiniciarAnimacion();
    }, 20000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.actualizarTop(event.target.innerWidth);
    this.actualizarMedidas(window.innerWidth);
  }

  public actualizarTop(windowWidth: number): void {
    if (windowWidth >= 1280 || windowWidth <= 768) {
      this.top = 'calc(40% + 55px)';
    } else if (windowWidth > 768 && windowWidth < 1280) {
      this.top = 'calc(40% + 80px)';
    } else {
      this.top = '40%';
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.actualizarClipPath();
  }

  public actualizarClipPath(): void {
    const texto = this.el.nativeElement.querySelector('.texto');
    const top = texto.offsetTop - window.scrollY;

    if ((window.innerWidth >= 1280 || window.innerWidth <= 768) && top < 110) {
      texto.style.clipPath = `inset(${this.recorte}% 0 0 0)`;
      this.recorte += 30;
    } else if ((window.innerWidth < 1280 || window.innerWidth > 768) && top < 160) {
      texto.style.clipPath = `inset(${this.recorte}% 0 0 0)`;
      this.recorte += 10;
    } else {
      texto.style.clipPath = 'inset(0 0 0 0)';
      this.recorte = 15;
    }
  }

  public actualizarMedidas(windowWidth: number): void {
    if (windowWidth <= 1150) {
      const disminucion = Math.floor((1150 - windowWidth) / 10);
      const nuevaAltura = Math.max(100 - disminucion, 10);
      document.documentElement.style.setProperty('--banner-height', `${nuevaAltura}vh`);
      document.documentElement.style.setProperty('--overlay-height', `${nuevaAltura}vh`);
    } else {
      document.documentElement.style.setProperty('--banner-height', '100vh');
      document.documentElement.style.setProperty('--overlay-height', '100vh');
    }
  }

  public consultarPorTipo() {
    this._configuracionService.consultarPorTipo().subscribe(
      result => {
        this.configuracion = new Configuracion();
        this.configuracion.inicializar(result);
      }
    );
  }

}