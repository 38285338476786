import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CotizacionComponent } from "./components/cotizacion/cotizacion.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { FacturaComponent } from "./components/factura/factura.component";
import { ListadoCotizacionesComponent } from "./components/listado-cotizaciones/listado-cotizaciones.component";
import { ListadoFacturasComponent } from "./components/listado-facturas/listado-facturas.component";
import { ListadoPrestamosComponent } from "./components/listado-prestamos/listado-prestamos.component";
import { PersonaComponent } from "./components/persona/persona.component";
import { PrestamoComponent } from "./components/prestamo/prestamo.component";
import { ProductosComponent } from "./components/productos/productos.component";
import { AuthGuard } from "./guards/auth.guard";
import { NosotrosComponent } from "./landing-page/components/nosotros/nosotros.component";
import { SucursalComponent } from "./components/sucursal/sucursal.component";
import { LandingPageComponent } from "./landing-page/landing-page/landing-page.component";
import { UsuariosComponent } from "./components/usuarios/usuarios.component";
import { ConfiguracionesComponent } from "./components/configuraciones/configuraciones.component";

const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        children: [
            {
                path: '',
                component: ProductosComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Producto'
                }
            },
            {
                path: 'productos',
                component: ProductosComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Producto'
                }
            },
            {
                path: 'personas',
                component: PersonaComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Persona'
                }
            },
            {
                path: 'facturas',
                component: ListadoFacturasComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Factura'
                }
            },
            {
                path: 'factura',
                component: FacturaComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Factura'
                }
            },
            {
                path: 'cotizaciones',
                component: ListadoCotizacionesComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Cotizacion'
                }
            },
            {
                path: 'cotizacion',
                component: CotizacionComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Cotizacion'
                }
            },
            {
                path: 'prestamos',
                component: ListadoPrestamosComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Prestamo'
                }
            },
            {
                path: 'prestamo',
                component: PrestamoComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Prestamo'
                }
            },
            {
                path: 'sucursal',
                component: SucursalComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Sucursal'
                }
            },
            {
                path: 'usuario',
                component: UsuariosComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Usuario'
                }
            },
            {
                path: 'configuracion',
                component: ConfiguracionesComponent,
                canActivate: [
                    AuthGuard
                ],
                data: {
                    controlador: 'Usuario'
                }
            }
        ]
    },
    {
        path: 'nosotros',
        component: NosotrosComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }