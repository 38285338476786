<div #divTabs class="tabs is-boxed">

    <ul>
        <li [ngClass]="pestannaGeneral ? 'is-active' : ''">
            <a>{{labels.parametrizable}}</a>
        </li>
    </ul>

</div>

<div class="parametrizable">

    <div class="label is-medium">
        <label class="level-left">{{labels.banner}}</label>
    </div>

    <div #divForm class="box">

        <form autocomplete="off">

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="level-left">{{labels.titulo}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small" type="text" name="tituloBanner"
                        [(ngModel)]="configuracion.tituloBanner">
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="level-left">{{labels.descripcion}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small" type="text" name="descripcionBanner"
                        [(ngModel)]="configuracion.descripcionBanner">
                </div>
            </div>

        </form>

    </div>

    <div class="label is-medium">
        <label class="level-left">{{labels.corporativa}}</label>
    </div>

    <div #divForm class="box div-config">

        <form autocomplete="off">

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.imagen}}</label>
                </div>
                <div class="field-body">
                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <div class="file is-small is-fullwidth">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="imagen" accept="image/png"
                                        (change)="archivoSeleccionado($event); abrirModalCropper()">
                                    <span #file class="file-cta">
                                        <span class="file-icon">
                                            <i class="{{iconoFile}}"></i>
                                        </span>
                                        <span class="file-label">
                                            {{labels.seleccionarImagen}}
                                        </span>
                                    </span>
                                    <span class="file-name">
                                        {{configuracion.nombreImagenEquipo}}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="level-left">{{labels.nosotros}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small" type="text" name="sobreNosotrosInformacion"
                        [(ngModel)]="configuracion.sobreNosotrosInformacion">
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="level-left">{{labels.mision}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small" type="text" name="mision" [(ngModel)]="configuracion.mision">
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="level-left">{{labels.vision}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small" type="text" name="vision" [(ngModel)]="configuracion.vision">
                </div>
            </div>

        </form>

        <div class="container-config">
            <div #imagenCropped class="imagen-cropped-config">
                <img *ngIf="croppedResult" [src]="croppedResult" alt="Imagen seleccionada">
            </div>
        </div>

    </div>

    <!-- Botones -->

    <div class="field is-grouped is-grouped-right">

        <div class="control">
            <button [disabled]="subiendoImagen == true" class="button is-small is-outlined" (click)="actualizar()">
                {{botones.guardar}}
            </button>
        </div>

    </div>

    <!-- FIN: Botones -->

</div>

<div class="modal" [ngClass]="modalCropper ? 'is-active' : ''">
    <div class="modal-background" (click)="cerrarModalCropper()"></div>
    <div class="modal-content modal-config">
        <div class="box">

            <div class="has-text-right">
                <a id="btn-cerrar-menu">
                    <span class="icon is-small has-text-dark" (click)="cerrarModalCropper()">
                        <i class="fas fa-times"></i>
                    </span>
                </a>
            </div>

            <div class="box" *ngIf="imagen">
                <angular-cropper #angularCropper class="cropper" [imageUrl]="imagen" [cropperOptions]="{
                    dragMode: 'move',
                    movable: false,
                    responsive: false,
                    aspectRatio: 600/400,
                    autoCropArea: 1,
                    restore: false,
                    guides: false,
                    center: false,
                    highlight: false,
                    cropBoxMovable: true,
                    cropBoxResizable: false,
                    toggleDragModeOnDblclick: false,
                    checkCrossOrigin: false
                }">
                </angular-cropper>

                <br>

                <div class="field">
                    <button class="button landing is-fullwidth" (click)="getCroppedImage()">
                        {{botones.aceptar}}
                    </button>
                </div>
            </div>

        </div>

    </div>
</div>