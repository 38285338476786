import { Component, OnInit, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { Producto } from 'src/app/models/producto';
import { ProductoService } from 'src/app/services/producto.service';

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrls: ['./carrusel.component.css'],
})
export class CarruselComponent implements OnInit, OnDestroy {

  public carrusel: any[] = [];

  public nextSliding: boolean = false;
  public prevSliding: boolean = false;
  public intervalo: any;
  public animating: boolean = false;
  public screen: boolean = false;
  @Output() carruselOculto: EventEmitter<boolean> = new EventEmitter<boolean>();

  public labels = {
    coleccion: 'Productos destacados'
  }

  constructor(
    private _productoService: ProductoService
  ) { }


  ngOnInit(): void {
    this.consultarProductoDestacado();
    this.intervalo = setInterval(() => {
      this.nextSlide();
    }, 5000);

    if (window.innerWidth > 450) {
      this.screen = true;
    } else {
      this.screen = false;
    }

    if (this.carrusel.length === 0) {
      this.hideComponent();
    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalo);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 450) {
      this.screen = true;
    } else {
      this.screen = false;
    }
  }

  public nextSlide(): void {
    if (!this.animating) {
      this.animating = true;
      clearInterval(this.intervalo);
      this.nextSliding = true;

      setTimeout(() => {
        this.nextSliding = false;
        this.carrusel.push(this.carrusel.shift());

        this.intervalo = setInterval(() => {
          this.nextSlide();
        }, 5000);

        this.animating = false;
      }, 1000);
    }
  }

  public prevSlide(): void {
    if (!this.animating) {
      this.animating = true;
      clearInterval(this.intervalo);
      this.prevSliding = true;

      this.carrusel.unshift(this.carrusel.pop());

      setTimeout(() => {
        this.prevSliding = false;

        this.intervalo = setInterval(() => {
          this.nextSlide();
        }, 5000);

        this.animating = false;
      }, 1000);
    }
  }

  public swipeleft(event) {
    this.nextSlide();
  }

  public swiperight(event) {
    this.prevSlide();
  }

  public consultarProductoDestacado() {
    this.carrusel = [];

    this._productoService.consultarProductosDestacados().subscribe(
      result => {
        if (result.resultado) {
          result.datos.forEach(element => {
            const producto: Producto = new Producto();
            producto.inicializar(element);

            this.carrusel.push(producto);
          });

          if (this.carrusel.length === 0) {
            this.hideComponent();
          }
        }
      }
    );
  }

  public hideComponent(): void {
    const carouselContainer = document.querySelector('.carousel-container') as HTMLElement;
    if (carouselContainer) {
      carouselContainer.style.display = 'none';
      this.carruselOculto.emit(true);
    }
  }
  
}