import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pago } from '../models/pago';
import { Utilidades } from '../models/utilidades';

@Injectable({
  providedIn: 'root'
})
export class PagoService {

  private url: string = environment.url + 'Pago';

  constructor(
    private _http: HttpClient
  ) { }

  public consultarInformeCSV (fechaInicial: Date, fechaFinal: Date): Observable<any> {
    const parametros: HttpParams = new HttpParams().
    set('token', Utilidades.obtenerUsuario().token).
    set('solicitud', 'informe_csv').
    set('fecha_inicial', fechaInicial.toString().split("T")[0]).
    set('fecha_final', fechaFinal.toString().split("T")[0]);

    return this._http.get(this.url, { params: parametros }).pipe(
      catchError(Utilidades.errorHandler)
    );
  }

  public crear(pago: Pago, idFactura): Observable<any> {
    const parametros: HttpParams = new HttpParams().
    set('token', Utilidades.obtenerUsuario().token).
    set('solicitud', 'crear').
    set('id_factura', idFactura).
    set('id_medio_pago', pago.medioPago.id).
    set('fecha', pago.fecha).
    set('valor', pago.valor).
    set('notas', pago.notas);

    return this._http.post(this.url, parametros).pipe(
      catchError(Utilidades.errorHandler)
    );
  }

  public actualizar(pago: Pago): Observable<any> {
    const parametros: HttpParams = new HttpParams().
    set('token', Utilidades.obtenerUsuario().token).
    set('solicitud', 'actualizar').
    set('id', pago.id).
    set('id_medio_pago', pago.medioPago.id).
    set('fecha', pago.fecha).
    set('valor', pago.valor).
    set('notas', pago.notas);

    return this._http.post(this.url, parametros).pipe(
      catchError(Utilidades.errorHandler)
    );
  }

  public eliminar(pago: Pago): Observable<any> {
    const parametros: HttpParams = new HttpParams().
    set('token', Utilidades.obtenerUsuario().token).
    set('solicitud', 'eliminar').
    set('id', pago.id);

    return this._http.post(this.url, parametros).pipe(
      catchError(Utilidades.errorHandler)
    );
  }

}
