import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  public anoActual: number;
  public contacto: any = {};

  public labels = {
    email: 'Email',
    enlaces: 'Enlaces de Interés',
    busqueda: 'Búsqueda',
    reversion: 'Formulario de Reversión',
    politicaEnvio: 'Política de Envío',
    politicaReembolso: 'Política de Reembolso',
    terminos: 'Términos del Servicio',
    suscribete: 'Suscríbete',
    informacion: 'Recibe información y ofertas en tu correo electrónico.',
    enviar: 'Enviar',
    copy: 'Copyright',
    derechos: 'Todos los derechos reservados.'
  }

  constructor() {
    this.anoActual = new Date().getFullYear();
  }

}