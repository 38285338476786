<div #divForm class="form-filtros">

    <div class="field is-grouped">

        <div class="control is-expanded has-icons-left">

            <app-input-timer [placeholder]="labels.buscar" [name]="filtro"
                (textoCompleto)="onFilterTextChanged($event)">
            </app-input-timer>


        </div>

        <div class="control">

            <a class="button is-small is-outlined" [routerLink]="['/dashboard/prestamo']">
                <span>
                    {{botones.nuevo}}
                </span>
                <span class="icon is-small">
                    <i class="fas fa-plus"></i>
                </span>
            </a>

        </div>

    </div>

</div>

<div class="div-tabla is-size-7" [style.height.px]="altoDivTabla">

    <div class="tabla-encabezado">
        <table class="table is-bordered">

            <thead>
                <tr>
                    <th #documentoCliente width="15%">{{columnas.documentoCliente}}</th>
                    <th #nombreCliente width="60%">{{columnas.nombreCliente}}</th>
                    <th #fechaPrestamo width="15%">{{columnas.fechaPrestamo}}</th>
                    <th width="10%">{{columnas.acciones}}</th>
                </tr>
            </thead>

        </table>
    </div>

    <div class="div-cuerpo-tabla">
        <table class="table is-bordered is-fullwidth">
            <tbody>

                <tr *ngFor="let prestamo of listadoPrestamos | filtroPrestamos: [ filtro ]">

                    <td [style.width.px]="thDocumentoCliente">{{prestamo.numeroDocumento}}</td>
                    <td [style.width.px]="thNombreCliente">{{prestamo.nombre}}</td>
                    <td [style.width.px]="thFechaPrestamo">{{prestamo.fechaPrestamo}}</td>
                    <td>

                        <div class="buttons is-centered">

                            <a class="button is-small landing" [routerLink]="['/dashboard/prestamo']"
                                [queryParams]="{id: prestamo.id}">
                                <span class="icon is-small">
                                    <i class="fas fa-edit"></i>
                                </span>
                            </a>

                        </div>

                    </td>

                </tr>

            </tbody>

        </table>
    </div>

    <app-paginacion #paginacion [nombreElementos]="labels.prestamos" (paginaSeleccionada)="consultarListado()"
        (cantidadSeleccionada)="consultarListado()">
    </app-paginacion>

</div>