<div #divTabs 
    *ngIf="appMovil" 
    class="tabs is-boxed">

    <ul>
       <li [ngClass]="pestannaGeneral ? 'is-active' : ''">
            <a
                (click)="establecerPestanna(true)">
                {{labels.informacionGeneral}}
            </a>
       </li> 
       <li [ngClass]="!pestannaGeneral ? 'is-active' : ''">
            <a
                (click)="establecerPestanna(false)">
                {{labels.detalles}}
            </a>
       </li> 
    </ul>

</div>

<div #divForm 
    class="form-filtros">

    <form 
        #frmCotizacion
        [style.display]="generalActivo ? '' : 'none'"
        autocomplete="off" 
        (keydown.enter)="$event.preventDefault()">

        <!-- Fecha -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.fecha}}</label>
            </div>
            <div class="field-body">
                
                <div class="control">

                    <input 
                        class="input is-small"
                        type="date"
                        name="fecha"
                        [(ngModel)]="cotizacion.fecha">

                </div>

            </div>
        </div>

        <!-- FIN: Fecha -->

        <!-- Cliente -->

        <app-txt-persona
            #txtCliente
            [persona]="cotizacion.cliente"
            [labelPersona]="labels.cliente"
            [tipoPersona]="tipoPersonaCliente"
            (personaSeleccionada)="clienteSeleccionado($event)">
        </app-txt-persona>

        <!-- FIN: Cliente -->

        <!-- Notas -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.notas}}</label>
            </div>
            <div class="field-body">

            <textarea 
                class="textarea is-small is-uppercase"
                rows="2"
                name="notas"
                [(ngModel)]="cotizacion.notas">
            </textarea>

            </div>
        </div>

        <!-- FIN: Notas -->

    </form>

    <br>

    <!-- Detalles -->

    <div 
        #divDetalles
        [style.display]="detallesActivo ? '' : 'none'"
        class="box lista-detalles"
        [style.height.px]="altoDetalles">

        <div class="columns is-mobile">

            <div class="column">
                <div class="label is-large">
                    <label class="level-left">{{labels.detalles}}</label>
                </div>
            </div>
            <div class="column is-narrow-mobile">
                <div class="buttons is-right">
                    <button 
                        class="button is-small is-outlined"
                        (click)="agregarDetalle()">
                        <span>{{botones.agregar}}</span>
                        <span class="icon is-small">
                            <i class="fas fa-plus"></i>
                        </span>
                    </button>
                </div>
            </div>

        </div>

        <app-detalle-cotizacion 
            *ngFor="let detalle of cotizacion.detalles; let i = index"
            [detalle]="detalle"
            [indiceDetalle]="i"
            (detalleEliminado)="detalleEliminado($event)"
            (totalCalculado)="calcularTotales()">
        </app-detalle-cotizacion>
        
    </div>

    <!-- FIN: Detalles -->

</div>

<br>

<!-- Totales -->

<div class="field is-grouped is-grouped-right">

    <div class="control">

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.totalIva}}</label>
            </div>
            <div class="field-body">

                <input 
                    class="input is-small"
                    type="text"
                    name="totalIva"
                    [(ngModel)]="cotizacion.totalIva"
                    disabled>

            </div>
        </div>


        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.precioTotal}}</label>
            </div>
            <div class="field-body">

                <input 
                    class="input is-small"
                    type="text"
                    name="precioTotal"
                    [(ngModel)]="cotizacion.precioTotal"
                    disabled>

            </div>
        </div>

    </div>

</div>

<!-- FIN: Totales -->

<!-- Botones -->

<div class="field is-grouped is-grouped-right">

    <div class="control">
        <button
            class="button is-small is-outlined"
            [routerLink]="'/factura'"
            [queryParams]="{ id_cotizacion: cotizacion.id }"
            [attr.disabled]="cotizacion.id == 0 ? '' : null">
            {{botones.facturar}}
        </button>
    </div>

    <div class="control">
        <button
            class="button is-small is-outlined"
            (click)="guardar()">
            {{botones.guardar}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-danger"
            [attr.disabled]="cotizacion.id == 0 ? '' : null"
            (click)="eliminarCotizacion()">
            {{botones.eliminar}}
        </button>
    </div>

    <div class="control">
        <button class="button is-small has-text-primary">
            <span class="icon is-small">
                <i class="fas fa-eraser"></i>
            </span>
        </button>
    </div>

</div>

<!-- FIN: Botones -->