import { Component, Input, OnInit } from '@angular/core';
import { Compra } from 'src/app/models/compra';
import { Producto } from 'src/app/models/producto';
import { Utilidades } from 'src/app/models/utilidades';
import { CompraService } from 'src/app/services/compra.service';

@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.css']
})
export class ComprasComponent implements OnInit {

  public compra: Compra = new Compra(); 
  @Input() 
  public producto: Producto = new Producto();

  /**
   * Propiedades del diseño
   */

  public mensaje: string = '';
  public msjSuccess: boolean = false;

  public labels = {
    fecha: 'Fecha',
    cantidad: 'Cantidad',
    cantidadInterna: 'Cantidad interna'
  }

  public columnas = {
    fecha: 'Fecha',
    cantidad: 'Cantidad',
    esInterna: 'Interna',
    acciones: 'Acciones'
  }

  public botones = {
    guardar: 'Guardar'
  }

  public mensajes = {
    ingresoGuardado: 'El ingreso se ha guardado correctamente',
    ingresoEliminado: 'El ingreso se ha eliminado correctamente'
  }

  constructor(
    private _compraService: CompraService
  ) {
    
  }

  ngOnInit(): void {

  }

  public crear() {
    this._compraService.crear(this.compra, this.producto.id).subscribe(
      result => {
        if(result.resultado) {
          this.compra.id = result.datos.id; 
          this.establecerMensaje(this.mensajes.ingresoGuardado, true);
          this.producto.compras.push(this.compra);
          this.ordernarListado();
          this.limpiar();
        }
      }
    );
  }

  public limpiar() {
    this.compra = Object.assign({}, new Compra());
  }

  public eliminar(indice: number) {
    const compra: Compra = this.producto.compras[indice];
    this._compraService.eliminar(compra.id).subscribe(
      result => {
        this.establecerMensaje(this.mensajes.ingresoEliminado, true);
        this.producto.compras.splice(indice, 1);
      }
    );
  }

  public establecerMensaje(mensaje: string, isSuccess: boolean = false) {
    this.mensaje = mensaje;
    this.msjSuccess = isSuccess;
    setTimeout(() => {
      this.mensaje = '';
    }, 3000);
  }

  public ordernarListado() {
    this.producto.compras.sort(function(compra1, compra2){
      const fecha1: Date = new Date(compra1.fecha);
      const fecha2: Date = new Date(compra2.fecha);
      
      return Number(fecha2) - Number(fecha1);
    });
  }

}