<div>

    <form autocomplete="off" spellcheck="false">

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">{{labels.fecha}}</label>
            </div>
            <div class="field-body">

                <div class="control">
                    <input 
                        class="input is-small"
                        type="date"
                        name="fecha"
                        [(ngModel)]="compra.fecha">
                </div>

            </div>
        </div>
        
        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">
                    {{labels.cantidad}}
                </label>
            </div>
            <div class="field-body">

                <div class="field is-grouped">

                    <div class="control">
                        <input 
                            class="input is-small"
                            type="text"
                            name="cantidad"
                            [(ngModel)]="compra.cantidad">
    
                    </div>

                    <div class="control">
                        <label class="checkbox">
                            <input 
                                type="checkbox"
                                [checked]="compra.esCantidadInterna"
                                (change)="compra.esCantidadInterna = $event.target.checked">
                            {{labels.cantidadInterna}}
                        </label>
                    </div>

                </div>

            </div>
        </div>

    </form>

    <br>

    <div class="columns">

        <div class="column">
            <div class="field">
                <div class="control">
                    <span 
                        class="tag is-light" 
                        [ngClass]="msjSuccess ? 'landing' : 'is-danger'"
                        *ngIf="mensaje">
                        {{mensaje}}
                    </span>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="buttons is-right">

                <button 
                    class="button is-small"
                    (click)="crear()">
                    {{botones.guardar}}
                </button>
    
                <button 
                    class="button is-small"
                    (click)="limpiar()">
                    <span class="icon is-small has-text-primary">
                        <i class="fas fa-eraser"></i>
                    </span>
                </button>
    
            </div>
        </div>

    </div>

</div>

<br>

<div class="div-tabla is-size-7">

    <table class="table is-bordered is-fullwidth">

        <thead>
            <tr>
                <th>{{columnas.fecha}}</th>
                <th>{{columnas.cantidad}}</th>
                <th width="10%">{{columnas.esInterna}}</th>
                <th width="5%">{{columnas.acciones}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let compra of producto.compras; let i = index">
                <td>{{compra.fecha}}</td>
                <td>{{compra.cantidad}}</td>
                <td>
                    <div class="control">
                        <div class="has-text-centered">
                            <label class="checkbox">
                                <input 
                                    type="checkbox"
                                    [checked]="compra.esCantidadInterna">
                            </label>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="buttons is-centered">
                        <button 
                            class="button is-small is-danger"
                            (click)="eliminar(i)">
                            <span class="icon is-small">
                                <i class="fas fa-trash-alt"></i>
                            </span>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>

    </table>

</div>