<app-carrusel (carruselOculto)="onCarruselOculto($event)"></app-carrusel>

<div class="borde" [style.margin-top.px]="carruselVisible ? 0 : 30">
    <div class="categorias">
        <div class="figure">
            <img src="./assets/figure.png" alt="">
        </div>

        <div class="contenido">
            <div class="header-container">
                <h1>{{ labels.categorias }}</h1>
                <br>
            </div>

            <div class="collage">
                <div class="columns">
                    <div class="categoria-container" *ngFor="let categoria of categorias.slice(0, categorias.length/2)">
                        <img [src]="categoria.src" [ngClass]="categoria.class">
                        <div class="categoria-content">
                            <div [ngClass]="categoria.class">
                                <p class="nombre">{{ categoria.nombre }}</p>
                                <span class="icon flecha">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="categoria-container" *ngFor="let categoria of categorias.slice(categorias.length/2)">
                        <img [src]="categoria.src" [ngClass]="categoria.class">
                        <div class="categoria-content">
                            <div [ngClass]="categoria.class">
                                <p class="nombre">{{ categoria.nombre }}</p>
                                <span class="icon flecha">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="boton">
                <button class="button landing-light">{{labels.boton}}</button>
            </div>
        </div>
    </div>
</div>

<br>

<hr>