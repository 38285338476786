import { Departamento } from "./departamento";

export class Municipio {

    constructor(
        public id: number = 0,
        public _nombre: string = '',
        public departamento: Departamento = new Departamento()
    ){ }

    /**
     * Encapsulamiento
     */

    get nombre(): string{
        return this._nombre.toUpperCase();
    }

    set nombre(value: string) {
        this._nombre = value.toString().toUpperCase();
    }

    /**
     * Métodos
     */

    public inicializar(datos){
        if(!!datos){
            this.id = !!datos.id ? datos.id : this.id;
            this.nombre = !!datos.nombre ? datos.nombre : this.nombre;
            this.departamento.inicializar(datos.departamento);
        }
    } 

}