import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PrestamoService } from 'src/app/services/prestamo.service';
import { PaginacionComponent } from '../paginacion/paginacion.component';

@Component({
  selector: 'app-listado-prestamos',
  templateUrl: './listado-prestamos.component.html',
  styleUrls: ['./listado-prestamos.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  },
  providers: [
    PrestamoService
  ]
})
export class ListadoPrestamosComponent implements OnInit, AfterViewInit {

  public listadoPrestamos: Array<any> = new Array<any>();

  /**
   * Propiedades del diseño
   */

  @ViewChild('divForm')
  public divForm: ElementRef;
  public altoDivTabla: number = 0;
  public filtro: string;
  @ViewChild('paginacion')
  public paginacionComponent: PaginacionComponent;
  @ViewChild('documentoCliente')
  public documentoCliente: ElementRef;
  @ViewChild('nombreCliente')
  public nombreCliente: ElementRef;
  @ViewChild('fechaPrestamo')
  public fechaPrestamo: ElementRef;
  public thDocumentoCliente: number;
  public thNombreCliente: number;
  public thFechaPrestamo: number;

  public labels = {
    buscar: 'Buscar',
    prestamos: 'Préstamos'
  }

  public botones = {
    nuevo: 'Crear préstamo'
  }

  public columnas = {
    documentoCliente: "N° Documento",
    nombreCliente: "Nombre / Razón social",
    fechaPrestamo: "Fecha de préstamo",
    acciones: "Acciones"
  }

  constructor(
    private _prestamoService: PrestamoService
  ) { }

  ngOnInit(): void {
    this.consultarListado();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  public onResize() {
    this.setColumnWidth();

    if (this.divForm) {
      let altoDivForm: number = this.divForm.nativeElement.offsetHeight;
      let altoDivTabla = window.innerHeight - altoDivForm - 120;

      if (altoDivTabla != this.altoDivTabla) {
        this.altoDivTabla = altoDivTabla;
      }
    }
  }

  public consultarListado() {
    this.listadoPrestamos = new Array<any>();

    this._prestamoService.consultarListado().subscribe(
      result => {

        this.paginacionComponent.cantidadTotalElementos = result.datos.length;
        if (result.resultado) {
          result.datos.forEach(item => {
            let prestamo: any = new Object();

            prestamo.id = item.id;
            prestamo.numeroDocumento = item.numero_documento;
            prestamo.nombre = item.nombre;
            prestamo.fechaPrestamo = item.fecha_prestamo;

            this.listadoPrestamos.push(prestamo);
          });

          this.paginacionComponent.cantidadElementos = this.listadoPrestamos.length;
          this.paginacionComponent.establecerPaginas();
          this.filtro = '';
        }
      }
    );
  }

  public setColumnWidth() {
    if (this.documentoCliente) {
      this.thDocumentoCliente = this.documentoCliente.nativeElement.offsetWidth;
      this.thNombreCliente = this.nombreCliente.nativeElement.offsetWidth;
      this.thFechaPrestamo = this.fechaPrestamo.nativeElement.offsetWidth;
    }
  }

  public onFilterTextChanged(filterText: string) {
    this.filtro = filterText;
  }

}
