import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Persona } from 'src/app/models/persona';
import { TipoPersona } from 'src/app/models/tipo-persona';
import { PersonaService } from 'src/app/services/persona.service';
import { PersonasComponent } from '../personas/personas.component';

@Component({
  selector: 'app-txt-persona',
  templateUrl: './txt-persona.component.html',
  styleUrls: ['./txt-persona.component.css'],
  providers: [
    PersonaService
  ]
})
export class TxtPersonaComponent implements OnInit, AfterViewInit {

  @Input()
  public nombrePersona: string = '';

  /**
   * Propiedades del diseño
   */
  @Input()
  public persona: Persona = new Persona();
  @Input()
  public labelPersona: string = '';
  @Input()
  public tipoPersona: TipoPersona;
  @Input()
  public consultarEmpleados: boolean;
  @Output()
  public personaSeleccionada: EventEmitter<Persona> = new EventEmitter<Persona>();
  @ViewChild('listadoPersonas')
  public listadoPersonas: PersonasComponent;
  public modalConsultarPersonasActivo: boolean = false;
  public modalCrearPersonaActivo: boolean = false;

  public labels = {
    numeroDocumento: 'Número de documento',
    nombreCompleto: 'Nombre completo / Razón social'
  };

  constructor(
    private _personaService: PersonaService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.listadoPersonas.setColumnWidth();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.listadoPersonas.setColumnWidth();

      this.listadoPersonas.altoTabla = 300;

      if (this.persona.id != 0) {
        this.nombrePersona = this.persona.getNombreCompleto();
      }
    });
  }

  public consultarPersonaPorNumeroDocumento() {
    if (!!this.persona.numeroDocumento) {
      this._personaService.consultarPorNumeroDocumento(this.persona.numeroDocumento).subscribe(
        result => {
          if (result.resultado) {
            this.persona = new Persona();
            this.nombrePersona = '';

            this.persona.inicializar(result.datos);
            this.nombrePersona = this.persona.getNombreCompleto();
            this.onPersonaSeleccionada();
          }
        }
      );
    } else {
      this.persona = new Persona();
      this.nombrePersona = '';
      this.onPersonaSeleccionada();
    }
  }

  public consultarPersonaPorId(id: number): Promise<Persona> {
    return new Promise((success, reject) => {
      this._personaService.consultarPorId(id).subscribe(
        result => {
          if (result.resultado) {
            let persona = new Persona();

            persona.inicializar(result.datos);
            success(persona);
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public personaSeleccionadaEnListado(id: number) {
    const promesa = this.consultarPersonaPorId(id);

    promesa.then(
      (persona) => {
        this.persona = persona;
        this.nombrePersona = this.persona.getNombreCompleto();
        this.cerrarModalConsultarPersonas();
        this.onPersonaSeleccionada();
      }
    );

    promesa.catch(
      (error) => { console.log(error); }
    );

  }

  public abrirModalConsultarPersonas() {
    if (this.consultarEmpleados == true) {
      this.listadoPersonas.consultarEmpleados();
    } else {
      if (this.tipoPersona) {
        this.listadoPersonas.consultarListadoPorTipo(this.tipoPersona)
      } else {
        this.listadoPersonas.consultarListado();
      }
    }

    this.modalConsultarPersonasActivo = true;
    setTimeout(() => {
      this.listadoPersonas.setColumnWidth();
    });
  }

  public cerrarModalConsultarPersonas() {
    this.modalConsultarPersonasActivo = false;
  }

  public abrirModalCrearPersona() {
    this.modalCrearPersonaActivo = true;
  }

  public cerrarModalCrearPersona() {
    this.modalCrearPersonaActivo = false;
  }

  public personaCreada(persona) {
    this.persona = persona;
    this.nombrePersona = this.persona.getNombreCompleto();
    this.cerrarModalCrearPersona();
  }

  public onPersonaSeleccionada() {
    this.personaSeleccionada.emit(this.persona);
  }

}
