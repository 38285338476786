<div
    class="dropdown"
    [ngClass]="listadoActivo ? 'is-active' : ''">
    
    <div class="dropdown-trigger">

        <input
            class="input is-small is-uppercase"
            type="text" 
            name="producto"
            placeholder="{{labels.producto}}"
            [(ngModel)]="filtroProducto"
            (keyup)="onChangedBusquedaProducto($event.key)">

    </div>

    <div class="dropdown-menu">

        <div class="dropdown-content">

            <a [ngClass]="itemActivo == producto ? 'dropdown-item is-active' : 'dropdown-item'"
                *ngFor="let producto of productosFiltrados"
                (click)="establecerProducto(producto.referencia)">
                {{producto.referencia}}: {{producto.detalle}}
            </a>

        </div>

    </div>

</div>