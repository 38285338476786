import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Utilidades } from "../models/utilidades";
import { Sucursal } from "../models/sucursal";

@Injectable({ providedIn: 'root' })
export class SucursalService {

    private url: string = environment.url + 'Sucursal';
    private tokenWeb: string = environment.tokenWeb;

    constructor(
        private _http: HttpClient
    ) { }

    public consultarListado(): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'consultar_listado');

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarPorId(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'consultar_por_id').
            set('id', id.toString());

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public crear(sucursal: Sucursal): Observable<any> {
        Utilidades.trim(sucursal);

        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'crear').
            set('id_empresa', usuario.sucursal.empresa.id).
            set('id_municipio', sucursal.municipio.id == 0 ? 'null' : sucursal.municipio.id).
            set('nombre', sucursal.nombre.toString()).
            set('ubicacion_maps', sucursal.ubicacionMaps.toString()).
            set('direccion', sucursal.direccion).
            set('nombre_imagen', sucursal.nombreImagen.toString()).
            set('habilitada', '1');

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public actualizar(sucursal: Sucursal): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(sucursal);
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'actualizar').
            set('id', sucursal.id.toString()).
            set('id_empresa', sucursal.empresa.id == 0 ? 'null' : sucursal.empresa.id).
            set('id_municipio', sucursal.municipio.id == 0 ? 'null' : sucursal.municipio.id).
            set('nombre', sucursal.nombre.toString()).
            set('ubicacion_maps', sucursal.ubicacionMaps.toString()).
            set('direccion', sucursal.direccion).
            set('nombre_imagen', sucursal.nombreImagen.toString()).
            set('habilitada', '1');

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public deshabilitar(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'deshabilitar').
            set('id', id.toString());

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public subirImagen(image: File): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const formData = new FormData();

        formData.append('token', usuario.token);
        formData.append('imagen', image);
        formData.append('solicitud', 'subir_imagen');

        return this._http.post(this.url, formData);
    }

    public consultarListadoWeb(): Observable<any> {
        const parametros: HttpParams = new HttpParams().
            set('token', this.tokenWeb).
            set('solicitud', 'consultar_listado');

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }
}