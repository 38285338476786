import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Remision } from "../models/remision";
import { Utilidades } from "../models/utilidades";
import { catchError } from "rxjs/operators";

@Injectable()
export class RemisionService {

    private url: string = environment.url + 'Remision';

    constructor(
        private _http: HttpClient
    ) { }

    public crear(remision: Remision, idFactura: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'crear').
        set('id_factura', idFactura).
        set('id_encargado', remision.encargado.id).
        set('notas', remision.notas).
        set('fecha_entrega', remision.fechaEntrega).
        set('fecha_instalacion', remision.fechaInstalacion);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public actualizar(remision: Remision): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'actualizar').
        set('id', remision.id).
        set('id_encargado', remision.encargado.id).
        set('notas', remision.notas).
        set('fecha_entrega', remision.fechaEntrega).
        set('fecha_instalacion', remision.fechaInstalacion);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public agregarSoporte(remision: Remision): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: FormData = new FormData();
        parametros.append('token', usuario.token);
        parametros.append('solicitud', 'agregar_soporte');
        parametros.append('id', remision.id.toString());
        parametros.append('archivo', remision.archivo, remision.archivo.name);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public eliminar(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'eliminar').
        set('id', id);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public imprimir(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'imprimir').
        set('id', id);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

}