<div #divForm class="form-filtros">

    <form #frmFactura autocomplete="off" (keydown.enter)="$event.preventDefault()">

        <!-- Número de factura (Prefijo y consecutivo) -->
        
        <div *ngIf="factura.id != 0" class="control is-expanded">
            
            <label class="label is-large level-right">
                {{labels.numeroFactura + ': ' + factura.consecutivo }}
            </label>
            
        </div>

        <div *ngIf="factura.id != 0" class="control is-expanded">
            
            <label class="label is-large level-right">
                {{labels.numeroFacturaE + ': ' + factura.consecutivo }}
            </label>
            
        </div>

        <!-- FIN: Número de factura (Prefijo y consecutivo) -->

        <!-- Fecha -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.fecha}}</label>
            </div>
            <div class="field-body">
                
                <div class="control">

                    <input 
                        class="input is-small"
                        type="date"
                        name="fecha"
                        [(ngModel)]="factura.fecha">

                </div>
                
            </div>
        </div>

        <!-- FIN: Fecha -->

        <!-- Cliente -->

        <app-txt-persona
            #txtCliente
            [labelPersona]="labels.cliente"
            [persona]="factura.cliente"
            (personaSeleccionada)="clienteSeleccionado($event)">
        </app-txt-persona>

        <!-- FIN: Cliente -->

        <!-- Vendedor -->

        <app-txt-persona
            #txtVendedor
            [labelPersona]="labels.vendedor"
            [tipoPersona]="tipoPersonaVendedor"
            [persona]="factura.vendedor"
            (personaSeleccionada)="vendedorSeleccionado($event)">
        </app-txt-persona>

        <!-- FIN: Vendedor -->

        <!-- Porcentaje comisión -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">
                    {{labels.comision}}
                </label>
            </div>
            <div class="field-body">
                <div class="control">

                    <input 
                        class="input is-small"
                        type="number"
                        name="porcentajeComision"
                        [(ngModel)]="factura.porcentajeComision"
                        (blur)="calcularTotales()">
                        
                </div>
            </div>
        </div>

        <!-- FIN: Porcentaje comisión -->

        <!-- Detalles -->

        <div class="box">

            <div class="field is-grouped">

                <!-- Cantidad -->

                <div 
                    class="control txt-pequenno"
                    [attr.data-tooltip]="detalleActual.cantidad == null ? null : labels.cantidad">
                    <input
                        class="input is-small" 
                        type="number"
                        placeholder="{{labels.cantidad}}"
                        name="cantidad"
                        [(ngModel)]="detalleActual.cantidad">
                </div>

                <!-- FIN: Cantidad -->

                <!-- Producto -->

                <div class="control">
                        
                    <app-select-productos
                        #selectProductos
                        (productoSeleccionado)="productoSeleccionado($event)">
                    </app-select-productos>

                </div>

                <!-- FIN: Producto -->

                <!-- Descripción -->

                <div
                    class="control is-expanded"
                    [attr.data-tooltip]="detalleActual.descripcion == null || detalleActual.descripcion.length == 0 ? null : labels.detalle">
                    <input 
                        class="input is-small is-uppercase"
                        type="text"
                        placeholder="{{labels.detalle}}"
                        name="detalle"
                        [(ngModel)]="detalleActual.descripcion">
                </div>

                <!-- FIN: Descripción -->

                <!-- IVA -->

                <div 
                    class="control txt-pequenno"
                    [attr.data-tooltip]="detalleActual.porcentajeIva == null ? null : labels.iva">
                    <input 
                        class="input is-small"
                        type="number"
                        placeholder="{{labels.iva}}"
                        name="iva"
                        [(ngModel)]="detalleActual.porcentajeIva">
                </div>

                <!-- FIN: IVA -->

                <!-- Descuento -->

                <div
                    class="control txt-pequenno"
                    [attr.data-tooltip]="detalleActual.porcentajeDescuento == null ? null : labels.descuento">
                    <input 
                        class="input is-small"
                        type="number"
                        placeholder="{{labels.descuento}}"
                        name="descuento"
                        [(ngModel)]="detalleActual.porcentajeDescuento">
                </div>

                <!-- FIN: Descuento -->

                <!-- Valor unitario -->

                <div 
                    class="control txt-valor"
                    [attr.data-tooltip]="detalleActual.precioUnitario == null ? null : labels.valorUnitario">
                    <input 
                        class="input is-small"
                        type="number"
                        placeholder="{{labels.valorUnitario}}"
                        name="valorUnitario"
                        [(ngModel)]="detalleActual.precioUnitario">
                </div>

                <!-- FIN: Valor unitario -->

                <div class="control">
                    <button 
                        class="button is-small landing"
                        (click)="agregarDetalle()"
                        (keydown.enter)="agregarDetalle()">
                        <span class="icon is-small">
                            <i class="fas fa-plus"></i>
                        </span>
                    </button>
                </div>

            </div>

        </div>

        <!-- FIN: Detalles -->

    </form>

</div>

<div #divTablaDetalles class="div-tabla is-size-7" [style.height.px]="altoTablaDetalles">

    <table class="table is-fullwidth is-bordered">

        <thead>

            <tr>
                <th width="3%">{{columnas.cantidad}}</th>
                <th width="8%">{{columnas.referenciaProducto}}</th>
                <th>{{columnas.descripcion}}</th>
                <th width="15%">{{columnas.valorUnitario}}</th>
                <th width="10%">{{columnas.iva}}</th>
                <th width="10%">{{columnas.descuento}}</th>
                <th width="13%">{{columnas.valorTotal}}</th>
                <th width="5%">{{columnas.instalacion}}</th>
            </tr>

        </thead>

        <tbody>

            <tr *ngFor="let detalle of factura.detalles; let i = index">

                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.cantidad}}</td>
                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.producto.referencia}}</td>
                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.descripcion}}</td>
                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.precioUnitario}}</td>
                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.valorIva}}</td>
                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.valorDescuento}}</td>
                <td (click)="detalleSeleccionado(detalle, i)">{{detalle.precioTotal}}</td>
                <td>

                    <div class="has-text-centered">
                        <label class="checkbox">
                            <input 
                                type="checkbox"
                                [(ngModel)]="detalle.esInstalacion"
                                (change)="establecerDetalleInstalacion(detalle, $event.target.checked)">
                        </label>
                    </div>

                </td>

            </tr>

        </tbody>

    </table>

</div>

<br>

<!-- Totales -->

    <div class="field is-grouped is-grouped-right">
        
        <div class="control">

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label>{{labels.totalDescuento}}</label>
                </div>
                <div class="field-body">
                    <div class="control txt-valor">
                        <input 
                            class="input is-small"
                            type="text"
                            name="totalDescuento"
                            [(ngModel)]="factura.totalDescuento"
                            disabled></div>
                </div>
            </div>

        </div>

    </div>

    <div class="field is-grouped is-grouped-right">
        
        <div class="control">

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label>{{labels.totalIva}}</label>
                </div>
                <div class="field-body">
                    <div class="control txt-valor">
                        <input 
                            class="input is-small"
                            type="text"
                            name="totalIva"
                            [(ngModel)]="factura.totalIva"
                            disabled></div>
                </div>
            </div>

        </div>

    </div>

    <div class="field is-grouped is-grouped-right">
        
        <div class="control">

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label>{{labels.totalFactura}}</label>
                </div>
                <div class="field-body">
                    <div class="control txt-valor">
                        <input 
                            class="input is-small"
                            type="text"
                            name="totalFactura"
                            [(ngModel)]="factura.valorTotal"
                            disabled></div>
                </div>
            </div>

        </div>

    </div>

    <div class="field is-grouped is-grouped-right">
        
        <div class="control">

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label>{{labels.valorComision}}</label>
                </div>
                <div class="field-body">
                    <div class="control txt-valor">
                        <input 
                            class="input is-small"
                            type="text"
                            name="comision"
                            [(ngModel)]="factura.valorComision"
                            disabled></div>
                </div>
            </div>

        </div>

    </div>

<!-- FIN: Totales -->

<div class="field is-grouped is-grouped-right">

    <div class="control">
        <button 
            class="button is-small is-danger"
            (click)="anularFactura()"
            [disabled]="factura.id == 0">
            {{botones.anular}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-outlined"
            (click)="imprimir()"
            [disabled]="factura.id == 0">
            {{botones.imprimir}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-outlined"
            (click)="abrirModalPagos()"
            [disabled]="factura.id == 0">
            {{botones.pagos}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-outlined"
            (click)="abrirModalRemision()"
            [disabled]="factura.id == 0">
            {{botones.remisionar}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small is-outlined"
            (click)="crear()"
            [disabled]="factura.id != 0">
            {{botones.guardar}}
        </button>
    </div>

    <div class="control">
        <button 
            class="button is-small"
            (click)="limpiarCampos()">
            <span class="icon is-small has-text-primary">
                <i class="fas fa-eraser"></i>
            </span>
        </button>
    </div>

</div>

<div class="modal"
        [ngClass]="modalRemisionActivo ? 'is-active' : ''">
    <div 
        class="modal-background"
        (click)="cerrarModalRemision()">
    </div>
    <div class="modal-content modal-listado">
        <div class="box">
            <app-remision #formRemision>
                
            </app-remision>
        </div>
    </div>
</div>

<div class="modal"
        [ngClass]="modalPagosActivo ? 'is-active' : ''">
    <div 
        class="modal-background"
        (click)="modalPagosActivo = false">
    </div>
    <div class="modal-content modal-listado">
        <div class="box">
            <app-pagos #formPagos
                [factura]="factura">
                
            </app-pagos>
        </div>
    </div>
</div>