import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Utilidades } from 'src/app/models/utilidades';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { PaginacionComponent } from '../paginacion/paginacion.component';

@Component({
  selector: 'app-listado-cotizaciones',
  templateUrl: './listado-cotizaciones.component.html',
  styleUrls: ['./listado-cotizaciones.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  },
  providers: [
    CotizacionService
  ]
})
export class ListadoCotizacionesComponent implements OnInit, AfterViewInit {

  public listadoCotizaciones: Array<any> = new Array<any>();
  public filtro: string;

  /**
   * Propiedades del diseño
   */
  public altoDivTabla: number = 0;
  @ViewChild('divForm')
  public divForm: ElementRef;
  @ViewChild('paginacion')
  public paginacionComponent: PaginacionComponent;
  @ViewChild('numeroDocumento')
  public numeroDocumento: ElementRef;
  @ViewChild('fecha')
  public fecha: ElementRef;
  @ViewChild('nombre')
  public nombre: ElementRef;
  public thNumeroDocumento: number;
  public thFecha: number;
  public thNombre: number;

  public labels = {
    buscar: 'Buscar',
    cotizaciones: 'Cotizaciones'
  }

  public botones = {
    nuevo: 'Crear cotización',
    excel: 'Exportar a excel'
  }

  public columnas = {
    documentoCliente: 'N° Documento',
    fecha: 'Fecha',
    nombreCliente: 'Nombre / Razón social',
    acciones: 'Acciones'
  }

  constructor(
    private _cotizacionService: CotizacionService
  ) { }

  ngOnInit(): void {
    this.consultarListado();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    }, 0);
  }

  public onResize() {
    this.setColumnWidth();

    if (this.divForm) {
      let altoDivForm: number = this.divForm.nativeElement.offsetHeight;
      let altoDivTabla = window.innerHeight - altoDivForm - 160;

      if (altoDivTabla != this.altoDivTabla) {
        this.altoDivTabla = altoDivTabla;
      }
    }
  }

  public consultarListado() {
    this.listadoCotizaciones = new Array<any>();

    this._cotizacionService.consultarListado().subscribe(
      result => {
        if (result.resultado) {

          this.paginacionComponent.cantidadTotalElementos = result.datos.length;
          result.datos.forEach(item => {
            let cotizacion: any = new Object();

            cotizacion.id = item.id;
            cotizacion.fecha = item.fecha;
            cotizacion.numeroDocumento = item.numero_documento;
            cotizacion.nombre = item.nombre.toUpperCase();

            this.listadoCotizaciones.push(cotizacion);
          });

          this.paginacionComponent.cantidadElementos = this.listadoCotizaciones.length;
          this.paginacionComponent.establecerPaginas();
          this.filtro = '';
        }
      }
    );
  }

  public exportarExcel() {
    this._cotizacionService.consultarInformeCsv(this.filtro).subscribe(
      result => {
        Utilidades.dialogImprimir(result.datos.ruta);
      }
    );
  }

  public setColumnWidth() {
    if (this.numeroDocumento) {
      this.thNumeroDocumento = this.numeroDocumento.nativeElement.offsetWidth;
      this.thFecha = this.fecha.nativeElement.offsetWidth;
      this.thNombre = this.nombre.nativeElement.offsetWidth;
    }
  }

  public onFilterTextChanged(filterText: string) {
    this.filtro = filterText;
  }

}
