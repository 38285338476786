import { TipoUsuario } from "./tipo-usuario";

export class Configuracion {
        
    constructor(
        public tituloBanner: string = '',
        public descripcionBanner: string = '',
        public nombreImagenEquipo: string = '',
        public sobreNosotrosInformacion: string = '',
        public mision: string = '',
        public vision: string = '',
        public tipo: TipoUsuario = new TipoUsuario(),
    ) {
        
    }

    public inicializar(datos: any): void {
        if(!!datos){
            this.tituloBanner = !!datos.titulo_banner ? datos.titulo_banner : '';
            this.descripcionBanner = !!datos.descripcion_banner ? datos.descripcion_banner : '';
            this.nombreImagenEquipo = !!datos.nombre_imagen_equipo ? datos.nombre_imagen_equipo : '';
            this.sobreNosotrosInformacion = !!datos.sobre_nosotros_informacion ? datos.sobre_nosotros_informacion : '';
            this.mision = !!datos.mision ? datos.mision : '';
            this.vision = !!datos.vision ? datos.vision : '';
            
            this.tipo.inicializar(datos.tipo);
        }
    }

}