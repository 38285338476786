import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-input-timer',
  templateUrl: './input-timer.component.html',
  styleUrls: ['./input-timer.component.css']
})
export class InputTimerComponent {

  @Input()
  public placeholder: string = '';
  @Input()
  public name: string = '';
  @Output()
  public textoCompleto = new EventEmitter<string>();
  public cambiosTexto = new Subject<string>();
  public filtroTexto: string = '';

  constructor() {
    this.cambiosTexto.pipe(debounceTime(500)).subscribe(value => {
      this.textoCompleto.emit(value);
    });
  }

  public onInputChange(event: any) {
    this.filtroTexto = event.target.value;
    this.cambiosTexto.next(this.filtroTexto);
  }
}
