<div #divForm class="div-form">

    <div class="div-sucursal">

        <form autocomplete="off">

            <!-- Nombre sucursal -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.nombre}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small is-uppercase" type="text" name="nombre" [(ngModel)]="sucursal.nombre">
                </div>
            </div>

            <!-- FIN: Nombre sucursal -->

            <!-- Localidad -->

            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="level-left">{{labels.localidad}}</label>
                </div>
                <div class="field-body">
                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <div class="select is-small is-fullwidth">
                                <app-select-departamentos #selectDepartamentos
                                    (departamentoSeleccionado)="departamentoSeleccionado($event)"
                                    [disabled]="sucursal.id > 0">
                                </app-select-departamentos>
                            </div>
                        </div>
                        <div class="control is-expanded">
                            <div class="select is-small is-fullwidth">
                                <app-select-municipios #selectMunicipios
                                    (municipioSeleccionado)="municipioSeleccionado($event)"
                                    [disabled]="sucursal.id > 0">
                                </app-select-municipios>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- FIN: Localidad -->

            <!-- Dirección -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.direccion}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small is-uppercase" type="text" name="direccion"
                        [(ngModel)]="sucursal.direccion">
                </div>
            </div>

            <!-- FIN: Dirección -->

            <!-- Url -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.url}}</label>
                </div>
                <div class="field-body">
                    <input class="input is-small" type="text" name="ubicacionMaps" [(ngModel)]="sucursal.ubicacionMaps">
                </div>
            </div>

            <!-- FIN: Url -->

            <!-- Imagen -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.imagen}}</label>
                </div>
                <div class="field-body">
                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <div class="file is-small is-fullwidth">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="imagen" accept="image/png"
                                        (change)="archivoSeleccionado($event); abrirModalCropper()">
                                    <span #file class="file-cta">
                                        <span class="file-icon">
                                            <i class="{{iconoFile}}"></i>
                                        </span>
                                        <span class="file-label">
                                            {{labels.seleccionarImagen}}
                                        </span>
                                    </span>
                                    <span class="file-name">
                                        {{sucursal.nombreImagen}}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>

        <div class="container-sucursal">
            <div #imagenCropped class="imagen-cropped-sucursal">
                <img *ngIf="croppedResult" [src]="croppedResult" alt="Imagen seleccionada">
            </div>
        </div>

        <!-- FIN: Imagen -->

    </div>

    <!-- Botones -->

    <div class="field is-grouped is-grouped-right boton-sucursal">

        <div class="control">
            <button [disabled]="subiendoImagen == true" class="button is-small is-outlined" (click)="guardar()">
                {{botones.guardar}}
            </button>
        </div>

        <div class="control" (click)="deshabilitarSucursal()">
            <button class="button is-small is-danger">{{botones.deshabilitar}}</button>
        </div>

        <div class="control">
            <button class="button is-small" (click)="limpiarCampos()">
                <span class="icon has-text-primary"><i class="fas fa-eraser"></i></span>
            </button>
        </div>

    </div>

    <!-- FIN: Botones -->

</div>

<div class="modal" [ngClass]="modalCropper ? 'is-active' : ''">
    <div class="modal-background" (click)="cerrarModalCropper()"></div>
    <div class="modal-content modal-sucursal">
        <div class="box">

            <div class="has-text-right">
                <a id="btn-cerrar-menu">
                    <span class="icon is-small has-text-dark" (click)="cerrarModalCropper()">
                        <i class="fas fa-times"></i>
                    </span>
                </a>
            </div>

            <div class="box" *ngIf="imagen">
                <angular-cropper #angularCropper class="cropper" [imageUrl]="imagen" [cropperOptions]="{
                    dragMode: 'move',
                    movable: false,
                    responsive: false,
                    aspectRatio: 435/300,
                    autoCropArea: 1,
                    restore: false,
                    guides: false,
                    center: false,
                    highlight: false,
                    cropBoxMovable: true,
                    cropBoxResizable: false,
                    toggleDragModeOnDblclick: false,
                    checkCrossOrigin: false
                }">
                </angular-cropper>

                <br>

                <div class="field">
                    <button class="button landing is-fullwidth" (click)="getCroppedImage()">
                        {{botones.aceptar}}
                    </button>
                </div>
            </div>

        </div>

    </div>
</div>

<div #divTabla class="box">

    <div class="div-tabla is-size-7" [style.height.px]="altoDivTabla">

        <div class="tabla-encabezado">
            <table class="table is-bordered">

                <thead>
                    <tr>
                        <th #nombre width="60%">{{columnas.nombre}}</th>
                        <th #direccion width="15%">{{columnas.direccion}}</th>
                        <th #municipio width="15%">{{columnas.municipio}}</th>
                        <th width="10%">{{columnas.acciones}}</th>
                    </tr>
                </thead>

            </table>
        </div>

        <div class="div-cuerpo-tabla">
            <table class="table is-bordered is-fullwidth">

                <tbody>

                    <tr *ngFor="let sucursal of sucursales">
                        <td [style.width.px]="thNombre">{{sucursal.nombre}}</td>
                        <td [style.width.px]="thDireccion">{{sucursal.direccion}}</td>
                        <td [style.width.px]="thMunicipio">{{sucursal.municipio.nombre}}</td>
                        <td>

                            <div class="buttons is-centered">
                                <button class="button is-small landing" (click)="consultarPorId(sucursal.id)">
                                    <span class="icon">
                                        <i class="fas fa-edit"></i>
                                    </span>
                                </button>
                            </div>

                        </td>
                    </tr>

                </tbody>

            </table>

        </div>

    </div>

</div>