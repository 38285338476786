import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Producto } from "../models/producto";
import { Utilidades } from "../models/utilidades";

@Injectable()
export class ProductoService {

    private url: string = environment.url + "Producto";
    private tokenWeb: string = environment.tokenWeb;

    constructor(
        private _http: HttpClient
    ) { }

    public consultarPorSucursal(): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'consultar_de_sucursal').
            set('id_sucursal', usuario.id);

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarProductoPorId(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'consultar_por_id').
            set('id', id.toString());

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public deshabilitarProducto(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'deshabilitar').
            set('id', id.toString());

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public crear(producto: Producto): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(producto);

        const cantidadInterna = !!producto.cantidadInterna ? producto.cantidadInterna : 0;
        const cantidadDisponible = !!producto.cantidadDisponible ? producto.cantidadDisponible : 0;

        let parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'crear').
            set('id_sucursal', usuario.id).
            set('detalle', producto.detalle == null ? '' : producto.detalle).
            set('cantidad_interna', cantidadInterna).
            set('cantidad_disponible', cantidadDisponible).
            set('nombre_imagen', producto.nombreImagen.toString()).
            set('destacado', producto.destacado ? '1' : '0').
            set('precio', producto.precio.toString());

        if (producto.referencia != '') {
            parametros = parametros.set('referencia', producto.referencia);
        }

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public actualizar(producto: Producto): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(producto);

        const cantidadInterna = !!producto.cantidadInterna ? producto.cantidadInterna : 0;
        const cantidadDisponible = !!producto.cantidadDisponible ? producto.cantidadDisponible : 0;

        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'actualizar').
            set('id', producto.id.toString()).
            set('detalle', producto.detalle).
            set('cantidad_interna', cantidadInterna.toString()).
            set('cantidad_disponible', cantidadDisponible.toString()).
            set('nombre_imagen', producto.nombreImagen.toString()).
            set('destacado', producto.destacado ? '1' : '0').
            set('precio', producto.precio.toString());

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarInformeSalidas(fechaInicial: Date, fechaFinal: Date): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'informe_salidas').
            set('id_sucursal', usuario.id).
            set('fecha_inicial', !!fechaInicial ? fechaInicial.toString().split("T")[0] : '').
            set('fecha_final', !!fechaFinal ? fechaFinal.toString().split("T")[0] : '');

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarInformeExistencias(): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'informe_existencias').
            set('id_sucursal', usuario.id);

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public subirImagen(image: File): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const formData = new FormData();

        formData.append('solicitud', 'subir_imagen');
        formData.append('token', usuario.token);
        formData.append('imagen', image);

        return this._http.post(this.url, formData);
    }

    public consultarProductosDestacados(): Observable<any> {
        const parametros: HttpParams = new HttpParams().
            set('token', this.tokenWeb).
            set('solicitud', 'consultar_destacados');

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

}