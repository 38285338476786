import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';
import { Configuracion } from 'src/app/models/configuracion';
import { Utilidades } from 'src/app/models/utilidades';
import { ConfiguracionService } from 'src/app/services/configuracion.service';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.css']
})
export class ConfiguracionesComponent implements OnInit {

  public configuracion: Configuracion = new Configuracion();

  public pestannaGeneral: boolean = true;
  public nombreArchivo: string = '';
  public modalCropper: boolean = false;
  public subiendoImagen: boolean = false;
  protected iconoFile: string = 'fas fa-upload';
  public imagen: string = '';
  public croppedResult: string = '';
  @ViewChild('imagenCropped')
  public imagenCropped: ElementRef;
  @ViewChild('angularCropper')
  public angularCropper: CropperComponent;

  public labels = {
    parametrizable: 'Landing page',
    banner: 'Banner',
    titulo: 'Título',
    descripcion: 'Descripción',
    corporativa: 'Información corporativa',
    mision: 'Misión',
    vision: 'Visión',
    nosotros: 'Sobre nosotros',
    imagen: 'Imagen del equipo',
    seleccionarImagen: 'Seleccionar imagen'
  }

  public botones = {
    guardar: 'Guardar',
    aceptar: 'Aceptar'
  };

  public mensajes = {
    configuracionesActualizadas: 'Las configuraciones se han actualizado correctamente.'
  }

  constructor(
    private _configuracionService: ConfiguracionService
  ) { }

  ngOnInit(): void {
    this.consultarPorTipo();
  }

  public archivoSeleccionado(event: Event): void {
    this.croppedResult = '';
    this.iconoFile = 'fas fa-spinner fa-spin';
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen = reader.result as string;
      };
      reader.readAsDataURL(inputElement.files[0]);
      this.nombreArchivo = inputElement.files[0].name;
    } else {
      this.imagen = '';
      this.nombreArchivo = '';
    }
  }

  public abrirModalCropper() {
    this.modalCropper = true;
  }

  public cerrarModalCropper() {
    this.modalCropper = false;
  }

  public getCroppedImage() {
    this.croppedResult = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    this.subiendoImagen = false;
    this.iconoFile = 'fas fa-check';
    this.cerrarModalCropper();
  }

  public actualizar() {
    this._configuracionService.actualizar(this.configuracion).subscribe(
      result => {
        if (result.resultado) {
          Utilidades.dialogSuccess(this.mensajes.configuracionesActualizadas);
        }
      }
    );
  }

  public consultarPorTipo() {
    this._configuracionService.consultarPorTipo().subscribe(
      result => {
        this.configuracion = new Configuracion();
        this.configuracion.inicializar(result);
      }
    );
  }

}