import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Utilidades } from '../models/utilidades';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
    private router: Router
  ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const usuario = Utilidades.obtenerUsuario();

    if(usuario == undefined || usuario == null) {
      this.redirect();
      return false;
    }

    let permisos: Array<any> = usuario.permisos;

    permisos = permisos.filter(permiso => {
      if(permiso.elemento.controlador == route.data.controlador) {
        return permiso;
      }
    });

    const logueado: boolean = permisos.length > 0;

    return logueado;
  }

  redirect(): any {
    this.router.navigate(['/']);
  }
  
}
