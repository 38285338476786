<div #divForm class="form-filtros">

    <div class="field is-grouped">

        <div class="control">

            <input class="input is-small" type="date" name="fechaInicial" [(ngModel)]="fechaInicialFiltro">

        </div>

        <div class="control">

            <input class="input is-small" type="date" name="fechaFinal" [(ngModel)]="fechaFinalFiltro">

        </div>

        <div class="control is-expanded has-icons-left">

            <app-input-timer [placeholder]="labels.buscar" [name]="filtroTexto"
                (textoCompleto)="onFilterTextChanged($event)">
            </app-input-timer>

        </div>

        <div class="control">

            <a class="button is-small is-outlined" [routerLink]="['/dashboard/factura']">
                <span>
                    {{botones.nuevo}}
                </span>
                <span class="icon is-small">
                    <i class="fas fa-plus"></i>
                </span>
            </a>

        </div>

    </div>

</div>

<div class="div-tabla is-size-7" [style.height.px]="altoDivTabla">

    <div class="tabla-encabezado">
        <table class="table is-bordered">

            <thead>
                <tr>
                    <th #consecutivo width="15%">{{columnas.numeroFactura}}</th>
                    <th #nombreCliente width="30%">{{columnas.nombreCliente}}</th>
                    <th #valorTotal width="15%">{{columnas.valorTotal}}</th>
                    <th #pagada width="15%">{{columnas.pagada}}</th>
                    <th #remisionada width="15%">{{columnas.remisionada}}</th>
                    <th width="10%">{{columnas.acciones}}</th>
                </tr>
            </thead>

        </table>
    </div>

    <div class="div-cuerpo-tabla">
        <table class="table is-bordered is-fullwidth">
            <tbody>

                <tr
                    *ngFor="let factura of facturas | filtroFacturas: [ filtroTexto, fechaInicialFiltro, fechaFinalFiltro ]">

                    <td [style.width.px]="thConsecutivo">{{factura.consecutivo}}</td>
                    <td [style.width.px]="thNombreCliente">{{factura.nombreCliente}}</td>
                    <td [style.width.px]="thValorTotal">{{factura.valorTotal}}</td>
                    <td [style.width.px]="thPagada">{{factura.pagada}}</td>
                    <td [style.width.px]="thRemisionada">{{factura.remisionada}}</td>
                    <td>
                        <div class="buttons is-centered">
                            <a class="button is-small landing" [routerLink]="['/dashboard/factura']"
                                [queryParams]="{id: factura.id}">
                                <span class="icon is-small">
                                    <i class="fas fa-edit"></i>
                                </span>
                            </a>
                        </div>
                    </td>

                </tr>

            </tbody>
        </table>
    </div>

    <app-paginacion #paginacion [nombreElementos]="labels.facturas" (paginaSeleccionada)="consultarListado()"
        (cantidadSeleccionada)="consultarListado()">
    </app-paginacion>

</div>

<div class="buttons is-right">
    <button class="button is-small is-outlined" (click)="exportarExcel()">
        <span>{{botones.excel}}</span>
        <span class="icon">
            <i class="fas fa-file-excel"></i>
        </span>
    </button>

    <button class="button is-small is-outlined" (click)="exportarPagosExcel()">
        <span>{{botones.excelPagos}}</span>
        <span class="icon">
            <i class="fas fa-file-excel"></i>
        </span>
    </button>

</div>