<div class="banner-container">
    <div class="texto-container">
        <p #texto class="texto" [style.top]="top">
            <span class="titulo">
                <h2>{{ configuracion.tituloBanner }}</h2>
            </span><br>
            {{ configuracion.descripcionBanner }}
        </p>
    </div>
    <div class="overlay"></div>
    <div class="overlayOscuro"></div>
    <div class="iframe">
        <iframe class="banner"
            src="https://www.youtube.com/embed/i24E5q8kkC4?playlist=i24E5q8kkC4&loop=1&autoplay=1&controls=0&mute=1&rel=0">
        </iframe>
    </div>
</div>