import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Utilidades } from "../models/utilidades";
import { Usuario } from "../models/usuario";

@Injectable({ providedIn: 'root' })
export class UsuarioService {

    private url: string = environment.url + 'Usuario';

    constructor(
        private _http: HttpClient
    ){}

    public login(usuario: string, contrasenna: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().
        set('solicitud', 'login').
        set('nombre', usuario).
        set('contrasenna', contrasenna);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public crear(user: Usuario): Observable<any>{
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(user);
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'crear').
        set('id_sucursal', usuario.id).
        set('id_tipo_usuario', user.tipo.id == 0 ? 'null' : user.tipo.id).
        set('nombre', user.nombre).
        set('contrasenna', user.contrasenna).
        set('id_persona', user.persona.id  == 0 ? 'null' : user.persona.id);

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public actualizar(user: Usuario): Observable<any>{
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(user);
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'actualizar').
        set('id', user.id.toString()).
        set('nombre', user.nombre)

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public actualizarContrasenna(user: Usuario): Observable<any>{
        const usuario = Utilidades.obtenerUsuario();
        Utilidades.trim(user);
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'cambiar_contrasenna').
        set('id', user.id.toString()).
        set('contrasenna', user.contrasenna)

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public eliminar(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
        set('token', usuario.token).
        set('solicitud', 'eliminar').
        set('id', id.toString())

        return this._http.post(this.url, parametros).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarUsuarios(): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'consultar_todos');

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

    public consultarPorId(id: number): Observable<any> {
        const usuario = Utilidades.obtenerUsuario();
        const parametros: HttpParams = new HttpParams().
            set('token', usuario.token).
            set('solicitud', 'consultar_por_id').
            set('id', id.toString());

        return this._http.get(this.url, { params: parametros }).pipe(
            catchError(Utilidades.errorHandler)
        );
    }

}