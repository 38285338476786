import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public contacto: any = {};

  public labels = {
    contacto: 'Contacto',
    formulario: 'Nuestro equipo se pondrá en contacto contigo',
    nombre: 'Nombre',
    apellido: 'Apellidos',
    email: 'Email',
    telefono: 'Teléfono',
    mensaje: 'Mensaje',
    aceptacion: 'He leído y acepto la',
    politica: 'Política de Privacidad',
    enviar: 'Solicita información',
    suscripcion: 'Recibir información y ofertas en tu correo electrónico'
  }

  constructor() { }

  ngOnInit(): void {
  }

  public onSubmit() {
    console.log('Datos del formulario:', this.contacto);
    this.resetForm();
  }

  public resetForm(form?: NgForm) {
    if (form) {
      form.reset();
    }
    this.contacto = {};
  }

}