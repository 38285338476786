import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorias-productos',
  templateUrl: './categorias-productos.component.html',
  styleUrls: ['./categorias-productos.component.css']
})
export class CategoriasProductosComponent implements OnInit {

  public carruselVisible: boolean = true;

  public categorias: any[] = [
    { src: './assets/categorias/papelColgadura.png', class: 'image1', nombre: 'Papel de colgadura' },
    { src: './assets/categorias/WPC.png', class: 'image2', nombre: 'WPC' },
    { src: './assets/categorias/mobiliario.png', class: 'image3', nombre: 'Mobiliario importado' },
    { src: './assets/categorias/iluminacion.png', class: 'image4', nombre: 'Iluminación' },
  ];

  labels = {
    categorias: 'Categorías de productos',
    boton: 'Ver todos los productos'
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    window.addEventListener("scroll", this.parallax.bind(this));
  }

  public parallax() {
    const parallax = document.querySelectorAll<HTMLElement>(".collage");

    for (let i = 0; i < parallax.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = parallax[i].getBoundingClientRect().top;

      if (elementTop < windowHeight - 100) {
        parallax[i].classList.add("active");
      }
    }
  }

  public onCarruselOculto(oculto: boolean): void {
    this.carruselVisible = !oculto;
  }

}