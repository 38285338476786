import { Empresa } from "./empresa";
import { Municipio } from "./municipio";
import { TipoDocumento } from "./tipo-documento";
import { TipoPersona } from "./tipo-persona";

export class Persona{

    constructor(
        public id: number = 0,
        public municipio: Municipio = new Municipio(),
        public tipo: TipoPersona = new TipoPersona(),
        public empresa: Empresa = new Empresa(),
        public tipoDocumento: TipoDocumento = new TipoDocumento(),
        public numeroDocumento: string = '',
        public _nombres: string = '',
        public _apellidos: string = '',
        public _razonSocial: string = '',
        public _direccion: string = '',
        public _telefono: string = '',
        public _email: string = ''
    ){}

    /**
     * Encapsulamiento
     */

    get nombres(): string{
        return this._nombres.toUpperCase();
    }

    set nombres(value: string) {
        this._nombres = value.toString().toUpperCase();
    }

    get apellidos() :string {
        return this._apellidos.toUpperCase();
    }

    set apellidos(value: string) {
        this._apellidos = value.toString().toUpperCase();
    }

    get razonSocial() :string {
        return this._razonSocial.toUpperCase();
    }

    set razonSocial(value: string) {
        this._razonSocial = value.toString().toUpperCase();
    }

    get direccion() :string {
        return this._direccion.toUpperCase();
    }

    set direccion(value: string) {
        this._direccion = value.toString().toUpperCase();
    }

    get telefono() :string {
        return this._telefono.toUpperCase();
    }

    set telefono(value: string) {
        this._telefono = value.toString().toUpperCase();
    }
    
    get email() :string {
        return this._email.toLowerCase();
    }

    set email(value: string) {
        this._email = value.toLowerCase();
    }

    /**
     * Métodos
     */

    public inicializar(datos){
        if(!!datos){
            this.id = !!datos.id ? datos.id : this.id;
            this.numeroDocumento = !!datos.numero_documento ? datos.numero_documento : this.numeroDocumento;
            this.nombres = !!datos.nombres ? datos.nombres : this.nombres;
            this.apellidos = !!datos.apellidos ? datos.apellidos : this.apellidos;
            this.razonSocial = !!datos.razon_social ? datos.razon_social : this.razonSocial;
            this.direccion = !!datos.direccion ? datos.direccion : this.direccion;
            this.telefono = !!datos.telefono ? datos.telefono : this.telefono;
            this.email = !!datos.email ? datos.email : this.email;

            this.tipoDocumento.inicializar(datos.tipo_documento);
            this.tipo.inicializar(datos.tipo);
            this.municipio.inicializar(datos.municipio);
            this.empresa.inicializar(datos.empresa);
        }
    }

    getNombreCompleto(): string {
        const nombreCompleto = !!this.razonSocial ? this.razonSocial : this.nombres + ' ' + this.apellidos;
        return nombreCompleto.toUpperCase();
    }

}