<div #divForm class="form-filtros">

    <div class="field is-grouped">

        <div class="control is-expanded has-icons-left">

            <app-input-timer [placeholder]="labels.buscar" [name]="filtro"
                (textoCompleto)="onFilterTextChanged($event)">
            </app-input-timer>

        </div>

        <div class="control">

            <a class="button is-small is-outlined" [routerLink]="['/dashboard/cotizacion']">
                <span>
                    {{botones.nuevo}}
                </span>
                <span class="icon is-small">
                    <i class="fas fa-plus"></i>
                </span>
            </a>

        </div>

    </div>

</div>

<div class="div-tabla is-size-7" [style.height.px]="altoDivTabla">

    <div class="tabla-encabezado">
        <table class="table is-bordered">

            <thead>
                <tr>
                    <th #numeroDocumento width="15%">{{columnas.documentoCliente}}</th>
                    <th #fecha width="15%">{{columnas.fecha}}</th>
                    <th #nombre width="60%">{{columnas.nombreCliente}}</th>
                    <th width="10%">{{columnas.acciones}}</th>
                </tr>
            </thead>

        </table>
    </div>

    <div class="div-cuerpo-tabla">
        <table class="table is-bordered is-fullwidth">
            <tbody>

                <tr *ngFor="let cotizacion of listadoCotizaciones | filtroCotizaciones: [ filtro ]">

                    <td [style.width.px]="thNumeroDocumento">{{cotizacion.numeroDocumento}}</td>
                    <td [style.width.px]="thFecha">{{cotizacion.fecha}}</td>
                    <td [style.width.px]="thNombre">{{cotizacion.nombre}}</td>
                    <td>

                        <div class="buttons is-centered">

                            <a class="button is-small landing" [routerLink]="['/dashboard/cotizacion']"
                                [queryParams]="{id: cotizacion.id}">
                                <span class="icon is-small">
                                    <i class="fas fa-edit"></i>
                                </span>
                            </a>

                        </div>

                    </td>

                </tr>

            </tbody>

        </table>
    </div>

    <app-paginacion #paginacion [nombreElementos]="labels.cotizaciones" (paginaSeleccionada)="consultarListado()"
        (cantidadSeleccionada)="consultarListado()">
    </app-paginacion>

</div>

<div class="buttons is-right">
    <button class="button is-small is-outlined" (click)="exportarExcel()">
        <span>{{botones.excel}}</span>
        <span class="icon">
            <i class="fas fa-file-excel"></i>
        </span>
    </button>
</div>