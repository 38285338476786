import { MedioPago } from "./medio-pago";

export class Pago {

    constructor(
        public id: number = 0,
        public medioPago: MedioPago = new MedioPago(),
        public fecha: string = new Date().toISOString().split("T")[0],
        public valor: number = 0,
        public notas: string = ''
    ){}

    public inicializar(datos) {
        if(!!datos) {
            this.id = !!datos.id ? datos.id : this.id;
            this.fecha = !!datos.fecha ? datos.fecha : this.fecha;
            this.valor = !!datos.valor ? datos.valor : this.valor;
            this.notas = !!datos.notas ? datos.notas : this.notas;

            this.medioPago.inicializar(datos.medio_pago);
        }
    }

}