import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Persona } from 'src/app/models/persona';
import { TipoPersona } from 'src/app/models/tipo-persona';
import { PersonaService } from 'src/app/services/persona.service';
import { PaginacionComponent } from '../paginacion/paginacion.component';

@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  },
  providers: [
    PersonaService
  ]
})
export class PersonasComponent implements OnInit {

  /**
   * Propiedades del diseño
   */

  public filtro: string;
  public personas: Array<any> = new Array<any>();
  @Output()
  public personaSeleccionada: EventEmitter<number> = new EventEmitter<number>();
  public altoTabla: number = 0;
  @Input()
  public esBuscador: boolean = false;
  @ViewChild('paginacion')
  public paginacionComponent: PaginacionComponent;
  @ViewChild('numeroDocumento')
  public numeroDocumento: ElementRef;
  @ViewChild('nombreCompleto')
  public nombreCompleto: ElementRef;
  @ViewChild('tipoPersona')
  public tipoPersona: ElementRef;
  public thNumeroDocumento: number;
  public thNombreCompleto: number;
  public thTipoPersona: number;

  public labels = {
    buscar: 'Buscar...',
    personas: 'Personas'
  };

  public columnas = {
    numeroDocumento: 'N° documento',
    nombreCompleto: 'Nombre completo / Razón social',
    tipoPersona: 'Tipo',
    acciones: 'Acciones'
  }

  constructor(
    private _personaService: PersonaService
  ) { }

  ngOnInit(): void {
    this.consultarListado();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  public onResize() {
    this.setColumnWidth();
  }

  public consultarListado() {
    this.personas = new Array<any>();

    this._personaService.consultarListado().subscribe(
      result => {
        if (result.resultado) {

          this.paginacionComponent.cantidadTotalElementos = result.datos.length;
          result.datos.forEach(element => {
            this.personas.push({
              id: element.id,
              numeroDocumento: element.numero_documento,
              nombreCompleto: element.nombre_completo,
              tipo: element.tipo
            });
          });

          this.paginacionComponent.cantidadElementos = this.personas.length;
          this.paginacionComponent.establecerPaginas();
        }
      }
    );
  }

  public consultarListadoPorTipo(tipoPersona: TipoPersona) {
    this.personas = new Array<any>();

    this._personaService.consultarListadoPorTipo(tipoPersona).subscribe(
      result => {
        if (result.resultado) {

          result.datos.forEach(element => {
            this.personas.push({
              id: element.id,
              numeroDocumento: element.numero_documento,
              nombreCompleto: element.nombre_completo,
              tipo: element.tipo
            });
          });
        }
      }
    );
  }

  public consultarEmpleados() {
    this.personas = new Array<Persona>();

    this._personaService.consultarEmpleados().subscribe(
      result => {
        if (result.resultado) {
          result.datos.forEach(element => {
            this.personas.push({
              id: element.id,
              numeroDocumento: element.numero_documento,
              nombreCompleto: element.nombre_completo,
              tipo: element.tipo
            });
          });
        }
      }
    );
  }

  private onPersonaSeleccionada(id: number) {
    this.personaSeleccionada.emit(id);
  }

  public setColumnWidth() {
    if (this.numeroDocumento) {
      this.thNumeroDocumento = this.numeroDocumento.nativeElement.offsetWidth;
      this.thNombreCompleto = this.nombreCompleto.nativeElement.offsetWidth;
      this.thTipoPersona = this.tipoPersona.nativeElement.offsetWidth;
    }
  }

  public onFilterTextChanged(filterText: string) {
    this.filtro = filterText;
  }

}
