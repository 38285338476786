<div class="carousel-container coleccion">

    <div class="carousel-controls-top">
        <button class="carousel-control-prev" type="button" (click)="prevSlide()" [disabled]="animating">
            <span class="icon">
                <i class="fas fa-chevron-left"></i>
            </span>
        </button>
        <h1>{{labels.coleccion}}</h1>
        <button class="carousel-control-next" type="button" (click)="nextSlide()" [disabled]="animating">
            <span class="icon">
                <i class="fas fa-chevron-right"></i>
            </span>
        </button>
    </div>

    <div class="carousel-inner" (swipeleft)="swipeleft($event)" (swiperight)="swiperight($event)">
        <div class="columns mayor" *ngIf="screen">
            <div class="column" [ngClass]="{'animate-next': nextSliding, 'animate-prev': prevSliding}"
                *ngFor="let slide of carrusel">
                <div class="overlay">
                    <img [src]="'https://' + slide.rutaImagen">
                    <p class="texto">{{slide.detalle}}<br>${{slide.precio}}</p>
                </div>
            </div>
        </div>

        <div class="columns menor" *ngIf="!screen">
            <div class="column" [ngClass]="{'animate-next': nextSliding, 'animate-prev': prevSliding}">
                <div class="overlay">
                    <img [src]="'https://' + carrusel[0].rutaImagen">
                    <p class="texto">{{carrusel[0].detalle}}<br>${{carrusel[0].precio}}</p>
                </div>
            </div>
            <div class="column" [ngClass]="{'animate-next': nextSliding, 'animate-prev': prevSliding}">
                <div class="overlay">
                    <img [src]="'https://' + carrusel[1].rutaImagen">
                    <p class="texto">{{carrusel[1].detalle}}<br>${{carrusel[1].precio}}</p>
                </div>
            </div>
            <div class="column" [ngClass]="{'animate-next': nextSliding, 'animate-prev': prevSliding}">
                <div class="overlay">
                    <img [src]="'https://' + carrusel[2].rutaImagen">
                    <p class="texto">{{carrusel[2].detalle}}<br>${{carrusel[2].precio}}</p>
                </div>
            </div>
            <div class="column" [ngClass]="{'animate-next': nextSliding, 'animate-prev': prevSliding}"
                *ngIf="nextSliding || prevSliding">
                <div class="overlay">
                    <img [src]="'https://' + carrusel[3].rutaImagen">
                    <p class="texto">{{carrusel[3].detalle}}<br>${{carrusel[3].precio}}</p>
                </div>
            </div>
        </div>
    </div>

</div>

<br>