export class TipoPersona {

    constructor(
        public id: number = 0,
        public _nombre: string = '',
        public esEmpleado: boolean = false
    ){}

    /**
     * Encapsulamiento
     */

     get nombre(): string {
        return this._nombre.toUpperCase();
    }

    set nombre(value: string) {
        this._nombre = value.toString().toUpperCase();
    }

    /**
     * Métodos
     */

    public inicializar(datos: any){
        if(!!datos){
            this.id = datos.id;
            this.nombre = datos.nombre;
            this.esEmpleado = Boolean(datos.es_empleado);
        }
    }

}