import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuComponent } from 'src/app/landing-page/components/menu/menu.component';
import { Usuario } from 'src/app/models/usuario';
import { Utilidades } from 'src/app/models/utilidades';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [
    UsuarioService
  ]
})
export class LoginComponent implements OnInit {

  public usuario: string = '';
  public contrasenna: string = '';
  public modalLogin: boolean = false;

  /**
   * Propiedades del diseño
   */

  public mensaje: string = '';

  public labels = {
    usuario: 'Usuario',
    contrasenna: 'Contraseña',
    mensajeLogin: 'Inicio de sesión',
    cuenta: 'CUENTΛ'
  };

  public botones = {
    login: 'Ingresar'
  }

  private mensajes = {
    loginIncorrecto: 'Usuario y contraseña incorrectos'
  }

  constructor(
    private _usuarioService: UsuarioService,
    private router: Router,
    private menu: MenuComponent
  ) { }

  ngOnInit(): void {

  }

  public login(): void {
    this._usuarioService.login(this.usuario, this.contrasenna).subscribe(
      result => {
        if (result.resultado) {
          let usuario: Usuario = new Usuario();
          usuario.inicializar(result.datos);
          Utilidades.establecerUsuario(usuario);
          this.router.navigate(['/dashboard']);
        } else {
          this.mensaje = this.mensajes.loginIncorrecto;
          setTimeout(() => {
            this.mensaje = '';
          }, 3000);
        }
      }
    );
  }

  public abrirModalLogin() {
    if (Utilidades.obtenerUsuario() != undefined && (this.router.url === '/' || this.router.url === '/nosotros' || this.router.url === '/#servicios')) {
      this.router.navigate(['/dashboard']);
    } else {
      this.modalLogin = true;
      this.menu.mostrarBotonWhatsApp = false;
      this.menu.mostrarIndicadores = false;
    }
  }

  public cerrarModalLogin() {
    this.modalLogin = false;
    this.menu.mostrarBotonWhatsApp = true;
    this.menu.mostrarIndicadores = true;
  }
}