<div>

    <form autocomplete="off" spellcheck="false">

        <!-- Fecha de entrega -->

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">
                    {{labels.fechaDespacho}}
                </label>
            </div>
            <div class="field-body">

                <div class="control">
                    <input
                        type="date"
                        name="fechaDespacho"
                        class="input is-small"
                        [(ngModel)]="remision.fechaEntrega">
                </div>

            </div>
        </div>

        <!-- FIN: Fecha de entrega -->

        <!-- Fecha de instalación -->

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">
                    {{labels.fechaInstalacion}}
                </label>
            </div>
            <div class="field-body">

                <div class="control">
                    <input
                        type="date"
                        name="fechaInstalacion"
                        class="input is-small"
                        [(ngModel)]="remision.fechaInstalacion">
                </div>

            </div>
        </div>

        <!-- FIN: Fecha de instalación -->

        <!-- Encargado -->

        <app-txt-persona
            #txtEncargado
            [labelPersona]="labels.despachador"
            [persona]="remision.encargado"
            [consultarEmpleados]="true"
            (personaSeleccionada)="encargadoSeleccionado($event)">
        </app-txt-persona>
       
        <!-- FIN: Encargado -->

        <!-- Notas -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.notas}}</label>
            </div>
            <div class="field-body">

            <textarea 
                class="textarea is-small is-uppercase"
                rows="2"
                name="notas"
                [(ngModel)]="remision.notas">
            </textarea>

            </div>
        </div>        

        <!-- FIN: Notas -->

        <!-- Documento de soporte -->

        <div class="field is-horizontal">
            <div class="field-label">
                <label class="level-left">{{labels.documentoSoporte}}</label>
            </div>
            <div class="field-body">

                <div class="control has-tooltip-right"
                    [attr.data-tooltip]="subiendoArchivo ? mensajes.subiendoArchivo : 
                                            remision.id == 0 ? mensajes.noExisteRemision : null">

                    <div class="file is-small">
                        <label class="file-label">
                            <input 
                            class="file-input" 
                            type="file" 
                            name="imagen"
                            accept="images/*"
                            (change)="archivoSeleccionado($event.target.files[0])"
                            [disabled]="subiendoArchivo || remision.id == 0">
                            <span class="file-cta">
                                <span class="file-icon">
                                    <ng-template [ngIf]="remision.urlArchivo.length == 0 && subiendoArchivo == false" >
                                        <i class="fas fa-upload"></i>
                                    </ng-template>
                                    <ng-template [ngIf]="subiendoArchivo" >
                                        <i class="fas fa-spinner fa-spin"></i>
                                    </ng-template>
                                    <ng-template [ngIf]="remision.urlArchivo.length" >
                                        <i class="fas fa-check"></i>
                                    </ng-template>
                                </span>
                                <span class="file-label">
                                    {{labels.seleccionarArchivo}}
                                </span>
                            </span>
                        </label>
                    </div>

                </div>

            </div>
        </div>

        <!-- FIN: Documento de soporte -->

    </form>

    <div class="buttons is-right">

        <!-- Eliminar -->

        <button 
            class="button is-danger is-small"
            [attr.disabled]="remision.id == 0 ? '' : null"
            (click)="eliminarRemision()">
            {{botones.eliminar}}
        </button>

        <!-- FIN: Eliminar -->

        <!-- Imprimir -->

        <button 
            class="button is-small is-outlined"
            [attr.disabled]="remision.id == 0 ? '' : null"
            (click)="imprimir()">
            {{botones.imprimir}}
        </button>

        <!-- FIN: Imprimir -->

        <!-- Guardar -->

        <button 
            class="button is-small is-outlined"
            (click)="guardar()">
            {{botones.guardar}}
        </button>

        <!-- FIN: Guardar -->

    </div>

</div>