import { Compra } from "./compra";
import { Empresa } from "./empresa";

export class Producto{

    constructor(
        public id: number = 0,
        public empresa: Empresa = new Empresa(),
        public compras: Array<Compra> = [],
        public _referencia: string = '',
        public _detalle: string = '',
        public cantidadInterna: number = null,
        public cantidadDisponible: number = null,
        public precio: number = 0.0,
        public nombreImagen: string = '',
        public habilitado: boolean = false,
        public destacado: boolean = false,
        public rutaImagen: string = ''
    ){}
    
    /**
     * Encapsulamiento
     */

    get referencia(): string { 
        return this._referencia.toUpperCase();
    }

    set referencia(value: string) {
        this._referencia = value.toString().toUpperCase();
    }

    get detalle(): string { 
        return this._detalle.toUpperCase();
    }

    set detalle(value: string) {
        this._detalle = value.toString().toUpperCase();
    }

    /**
     * Métodos
     */

    public inicializar(datos: any){
        if(!!datos){
            this.id = !!datos.id ? datos.id : this.id;
            this.empresa = new Empresa();
            this.referencia = !!datos.referencia ? datos.referencia : this.referencia;
            this.detalle = !!datos.detalle ? datos.detalle : this.detalle;
            this.cantidadInterna = !!datos.cantidad_interna ? datos.cantidad_interna : this.cantidadInterna;
            this.cantidadDisponible = !!datos.cantidad_disponible ? datos.cantidad_disponible : this.cantidadDisponible;
            this.precio = !!datos.precio ? datos.precio : this.precio;
            this.nombreImagen = !!datos.nombre_imagen ? datos.nombre_imagen : this.nombreImagen;
            this.habilitado = !!datos.habilitado ? datos.habilitado == 1 : this.habilitado;
            this.destacado = !!datos.destacado ? datos.destacado == 1 : this.destacado;
            this.rutaImagen = !!datos.ruta_imagen ? datos.ruta_imagen : this.rutaImagen;

            this.empresa.inicializar(datos.empresa);

            if(datos.compras) {
                datos.compras.map(obj => {
                    const compra = new Compra();
                    compra.inicializar(obj);
                    this.compras.push(compra);
                });
            }
        }
    }

}