<div #divForm class="div-form">

    <form autocomplete="off">

        <div class="field is-horizontal">

            <div class="field-label">
                <label class="level-left">{{labels.usuario}}</label>
            </div>

            <div class="field-body">

                <div class="field is-grouped">

                    <div class="control">

                        <div class="select is-small is-fullwidth">
                            <app-select-tipos-usuario [tipoUsuario]="usuario.tipo"
                                (tipoPersonaSeleccionado)="tipoUsuarioSeleccionado($event)">
                            </app-select-tipos-usuario>
                        </div>

                    </div>

                    <div class="control is-expanded">

                        <input class="input is-small" type="text" name="nombre" [(ngModel)]="usuario.nombre"
                            placeholder="{{labels.nombre}}">

                    </div>

                </div>

            </div>

        </div>

        <app-txt-persona #txtPersona [persona]="usuario.persona" [labelPersona]="labels.persona"
            (personaSeleccionada)="personaSeleccionada($event)">
        </app-txt-persona>

        <div class="field is-horizontal">

            <div class="field-label">
                <label class="level-left">{{labels.contrasenna}}</label>
            </div>

            <div class="field-body">

                <div class="field is-grouped">

                    <div class="control is-expanded">

                        <input class="input is-small" type="{{verContrasenna ? 'text' : 'password'}}" name="contrasenna"
                            [(ngModel)]="contrasenna" placeholder="{{labels.contrasenna}}"
                            [disabled]="usuario.id > 0" (blur)="validarContrasenna(contrasenna)">
                        <span class="icon is-small is-right" (click)="visibilidadContrasenna()">
                            <i class="fa" [ngClass]="{'fa-eye': !verContrasenna, 'fa-eye-slash': verContrasenna}"></i>
                        </span>

                    </div>

                    <div class="control is-expanded">

                        <input class="input is-small" type="{{verContrasennaConfirmada ? 'text' : 'password'}}" name="contrasenna"
                            [(ngModel)]="contrasennaConfirmada" placeholder="{{labels.confirmar}}"
                            [disabled]="usuario.id > 0" (blur)="validarContrasennasIguales()">
                        <span class="icon is-small is-right" (click)="visibilidadContrasennaConfirmada()">
                            <i class="fa" [ngClass]="{'fa-eye': !verContrasennaConfirmada, 'fa-eye-slash': verContrasennaConfirmada}"></i>
                        </span>

                    </div>

                </div>

            </div>
        
        </div>

        <span class="has-text-danger" [style.display]="'block'">
            {{labels.valida}}
        </span>
        <span class="has-text-danger" [style.display]="'block'">
            {{labels.iguales}}
        </span>

        <div class="field is-grouped is-grouped-right boton-usuarios">

            <div class="control">
                <button class="button is-small is-outlined" (click)="guardar()">
                    {{botones.guardar}}
                </button>
            </div>

            <div class="control">
                <button class="button is-small is-danger" [disabled]="usuario.id == 0" (click)="eliminar()">
                    {{botones.eliminar}}
                </button>
            </div>

            <div class="control">
                <button class="button is-small" (click)="limpiar()">
                    <span class="icon has-text-primary"><i class="fas fa-eraser"></i></span>
                </button>
            </div>

        </div>

    </form>

</div>

<div #divTabla class="box">

    <div class="div-tabla is-size-7" [style.height.px]="altoDivTabla">

        <div class="tabla-encabezado">
            <table class="table is-bordered">

                <thead>
                    <tr>
                        <th #tipo width="20%">{{columnas.tipo}}</th>
                        <th #nombre width="70%">{{columnas.nombre}}</th>
                        <th width="10%">{{columnas.acciones}}</th>
                    </tr>
                </thead>

            </table>
        </div>

        <div class="div-cuerpo-tabla">
            <table class="table is-bordered is-fullwidth">

                <tbody>

                    <tr *ngFor="let usuario of usuarios">
                        <td [style.width.px]="thTipo">{{usuario.tipo.nombre}}</td>
                        <td [style.width.px]="thNombre">{{usuario.nombre}}</td>
                        <td>

                            <div class="buttons is-centered">
                                <button class="button is-small landing" (click)="consultarPorId(usuario.id)">
                                    <span class="icon">
                                        <i class="fas fa-edit"></i>
                                    </span>
                                </button>
                            </div>

                        </td>
                    </tr>

                </tbody>

            </table>

        </div>

    </div>

</div>