import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs'
import { Sucursal } from 'src/app/models/sucursal';
import { SelectMunicipiosComponent } from '../select-municipios/select-municipios.component';
import { SelectDepartamentosComponent } from '../select-departamentos/select-departamentos.component';
import { SucursalService } from 'src/app/services/sucursal.service';
import { Utilidades } from 'src/app/models/utilidades';

@Component({
  selector: 'app-sucursal',
  templateUrl: './sucursal.component.html',
  styleUrls: ['./sucursal.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class SucursalComponent implements OnInit {

  public sucursal: Sucursal = new Sucursal();
  public sucursales: Array<any> = new Array<any>();

  public nombreArchivo: string = '';
  public imagen: string = '';
  public croppedResult: string = '';

  /**
   * Propiedades del diseño
   */

  public modalCropper: boolean = false;
  public subiendoImagen: boolean = false;
  protected iconoFile: string = 'fas fa-upload';
  @ViewChild('divForm')
  public divForm: ElementRef;
  public altoDivTabla: number;
  @ViewChild('imagenCropped')
  public imagenCropped: ElementRef;
  @ViewChild('angularCropper')
  public angularCropper: CropperComponent;
  public leftImagen: number;
  @ViewChild('selectMunicipios')
  private selectMunicipios: SelectMunicipiosComponent;
  @ViewChild('selectDepartamentos')
  private selectDepartamentos: SelectDepartamentosComponent;
  @ViewChild('nombre')
  public nombre: ElementRef;
  @ViewChild('direccion')
  public direccion: ElementRef;
  @ViewChild('municipio')
  public municipio: ElementRef;
  public thNombre: number;
  public thDireccion: number;
  public thMunicipio: number;

  public labels = {
    nombre: 'Nombre sucursal',
    localidad: 'Localidad',
    url: 'Url de la ubicación',
    imagen: 'Imagen de la sucursal',
    seleccionarImagen: 'Seleccionar imagen',
    direccion: 'Dirección'
  };

  public botones = {
    guardar: 'Guardar',
    deshabilitar: 'Deshabilitar',
    aceptar: 'Aceptar'
  };

  public columnas = {
    nombre: 'Nombre',
    direccion: 'Dirección',
    municipio: 'Municipio',
    acciones: 'Acciones'
  }

  public mensajes = {
    sucursalCreada: 'La sucursal se ha creado correctamente.',
    sucursalActualizada: 'La sucursal se ha actualizado correctamente.',
    sucursalDeshabilitada: 'La sucursal se ha deshabilitado correctamente.',
    imagen: 'La imagen es obligatoria.'
  }

  constructor(
    private _sucursalService: SucursalService
  ) { }


  ngOnInit(): void {
    this.consultarListado();
    this.setColumnWidth();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    });
  }

  public onResize() {
    this.setColumnWidth();

    if (this.divForm) {
      let altoDivForm: number = this.divForm.nativeElement.offsetHeight;
      let altoDivTabla = window.innerHeight - altoDivForm - 160;

      if (altoDivTabla != this.altoDivTabla) {
        this.altoDivTabla = altoDivTabla;
      }
    }
  }

  protected limpiarCampos() {
    this.sucursal = new Sucursal();
    this.croppedResult = '';
    this.establecerMunicipio();
    this.iconoFile = 'fas fa-upload';
  }

  public archivoSeleccionado(event: Event): void {
    this.croppedResult = '';
    this.iconoFile = 'fas fa-spinner fa-spin';
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagen = reader.result as string;
      };
      reader.readAsDataURL(inputElement.files[0]);
      this.nombreArchivo = inputElement.files[0].name;
    } else {
      this.imagen = '';
      this.nombreArchivo = '';
    }
  }

  public abrirModalCropper() {
    this.modalCropper = true;
  }

  public cerrarModalCropper() {
    this.modalCropper = false;
  }

  public getCroppedImage() {
    const croppedCanvas = this.angularCropper.cropper.getCroppedCanvas();
    const maxDimensionWidth = 435;
    const maxDimensionHeight = 300;

    if (croppedCanvas.width > maxDimensionWidth || croppedCanvas.height > maxDimensionHeight) {
      const resizedCanvas = document.createElement('canvas');
      const ctx = resizedCanvas.getContext('2d');
      resizedCanvas.width = maxDimensionWidth;
      resizedCanvas.height = maxDimensionHeight;
      ctx.drawImage(croppedCanvas, 0, 0, maxDimensionWidth, maxDimensionHeight);

      this.croppedResult = resizedCanvas.toDataURL();
    } else {
      this.croppedResult = croppedCanvas.toDataURL();
    }

    fetch(this.croppedResult)
      .then(res => res.blob())
      .then(blob => {
        const imagen: File = new File([blob], "imagen.png", { type: "image/png" })
        this.subiendoImagen = true;

        this._sucursalService.subirImagen(imagen).subscribe(
          result => {
            this.subiendoImagen = false;
            this.iconoFile = 'fas fa-check';
            if (result.resultado) {
              this.sucursal.nombreImagen = result.datos.nombre;
            }
          }
        );
      });

    this.cerrarModalCropper();
  }

  public departamentoSeleccionado(departamento) {
    this.sucursal.municipio.departamento = departamento;
    this.selectMunicipios.departamento = this.sucursal.municipio.departamento;
    this.selectMunicipios.consultarMunicipios();
  }

  public municipioSeleccionado(municipio) {
    this.sucursal.municipio = municipio;
  }

  public consultarSucursales() {
    this._sucursalService.consultarListado().subscribe(
      result => {
        if (result.resultado) {
          this.sucursales = result.datos;
        }
      }
    );
  }

  public consultarPorId(id: number) {
    this._sucursalService.consultarPorId(id).subscribe(
      result => {
        if (result.resultado) {
          this.sucursal = new Sucursal();
          this.sucursal.inicializar(result.datos);
          this.croppedResult = 'https://' + result.datos.ruta_imagen;

          if (result.datos.ruta_imagen) {
            this.iconoFile = 'fas fa-check';
          }

          this.establecerMunicipio();
        }
      }
    );
  }

  public guardar() {
    if (this.sucursal.nombreImagen == '') {
      Utilidades.dialogError(this.mensajes.imagen);
      return;
    }

    if (this.sucursal.id == 0) {
      this.crear();
    } else {
      this.actualizar();
    }
  }

  private crear() {
    this._sucursalService.crear(this.sucursal).subscribe(
      result => {
        if (result.resultado) {
          this.sucursal.id = result.datos.id;
          Utilidades.dialogSuccess(this.mensajes.sucursalCreada);
          this.consultarSucursales();
        }
      }
    );
  }

  private actualizar() {
    this._sucursalService.actualizar(this.sucursal).subscribe(
      result => {
        if (result.resultado) {
          Utilidades.dialogSuccess(this.mensajes.sucursalActualizada);
          this.consultarSucursales();
        }
      }
    );
  }

  public deshabilitarSucursal() {
    this._sucursalService.deshabilitar(this.sucursal.id).subscribe(
      result => {
        if (result.resultado) {
          Utilidades.dialogSuccess(this.mensajes.sucursalDeshabilitada);
          this.limpiarCampos();
          this.consultarSucursales();
        }
      }
    );
  }

  public consultarListado() {
    this.sucursales = [];

    this._sucursalService.consultarListado().subscribe(
      result => {
        if (result.resultado) {
          result.datos.forEach(element => {
            const sucursal: Sucursal = new Sucursal();
            sucursal.inicializar(element);

            this.sucursales.push(sucursal);
          });
        }
      }
    );
  }

  public setColumnWidth() {
    if (this.nombre) {
      this.thNombre = this.nombre.nativeElement.offsetWidth;
      this.thDireccion = this.direccion.nativeElement.offsetWidth;
      this.thMunicipio = this.municipio.nativeElement.offsetWidth;
    }
  }

  private establecerMunicipio() {
    this.selectDepartamentos.departamento = this.sucursal.municipio.departamento;
    this.selectMunicipios.departamento = this.sucursal.municipio.departamento;
    this.selectMunicipios.consultarMunicipios(this.sucursal.municipio);
  }

}