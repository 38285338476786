<div id="paginacion" class="columns">

    <div class="column is-4 is-align-self-center">
        <div class="field has-addons">
            <div class="control select is-small mr-1">
                <select 
                    name="cantidad"
                    [(ngModel)]="cantidadVisible"
                    (change)="onCantidadSeleccionada()">
                    <option [ngValue]="25">25</option>                    
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                    <option [ngValue]="0">{{labels.todosElementos}}</option>
                </select>
            </div>
            <div class="control label is-small is-align-self-center" [ngClass]="cantidadVisible == 0 ? 'is-hidden' : ''">
                <label>
                    {{labels.elementosVisibles}}
                </label>
            </div>
        </div>
    </div>

    <div class="column">

        <div class="buttons has-addons is-centered" [ngClass]="paginas.length == 1 ? 'is-hidden' : ''">

            <button class="button is-small"
                [disabled]="paginaActual == 1"
                (click)="seleccionarPagina(1)">
                <span class="icon">
                    <i class="fas fa-angle-double-left"></i>
                </span>
            </button>

            <button class="button is-small" 
                (click)="moverPagina(false)"
                [disabled]="paginaActual == 1">
                <span class="icon">
                    <i class="fas fa-chevron-left"></i>
                </span>
            </button>

            <div class="control" *ngFor="let item of paginas; let i = index">
                <button *ngIf="i >= inicioVisible && i <= finVisible"
                    class="button is-small"
                    [ngClass]="paginaActual == item ? 'landing' : ''"
                    (click)="seleccionarPagina(item)">
                    {{item.toString()}}
                </button>
            </div>

            <button class="button is-small" 
                (click)="moverPagina(true)"
                [disabled]="paginaActual == obtenerUltimaPagina()">
                <span class="icon">
                    <i class="fas fa-chevron-right"></i>
                </span>
            </button>

            <button 
                class="button is-small"
                [disabled]="paginaActual == paginas[paginas.length - 1]"
                (click)="seleccionarPagina(obtenerUltimaPagina())">
                <span class="icon">
                    <i class="fas fa-angle-double-right"></i>
                </span>
            </button>

        </div>

    </div>

    <div class="column label is-small is-4 pagina-actual control">
        <label>
            {{labels.paginaActual}}
        </label>
    </div>

</div>