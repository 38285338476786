<div #divForm class="form-filtros">

    <div class="productos">

        <form autocomplete="off">

            <!-- Producto -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.producto}}</label>
                </div>
                <div class="field-body">

                    <div class="field is-grouped">

                        <div class="control is-expanded">
                            <input class="input is-small is-uppercase" type="text" placeholder="{{labels.referencia}}"
                                name="referencia" [(ngModel)]="producto.referencia">
                        </div>

                        <div class="control is-expanded">
                            <input class="input is-small is-uppercase" type="text" placeholder="{{labels.detalle}}"
                                name="detalle" [(ngModel)]="producto.detalle">
                        </div>

                    </div>

                </div>
            </div>

            <!-- FIN: Producto -->

            <!-- Imagen -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.imagen}}</label>
                </div>
                <div class="field-body">
                    <div class="field is-grouped">
                        <div class="control is-expanded">
                            <div class="file is-small is-fullwidth">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="imagen" accept="image/png"
                                        (change)="archivoSeleccionado($event); abrirModalCropper()">
                                    <span #file class="file-cta">
                                        <span class="file-icon">
                                            <i class="{{iconoFile}}"></i>
                                        </span>
                                        <span class="file-label">
                                            {{labels.seleccionarImagen}}
                                        </span>
                                    </span>
                                    <span class="file-name">
                                        {{producto.nombreImagen}}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- FIN: Imagen -->

            <!-- Cantidad -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.cantidad}}</label>
                </div>
                <div class="field-body">

                    <div class="field is-grouped">

                        <div class="control is-expanded"
                            [attr.data-tooltip]="producto.cantidadDisponible == null ? null : labels.cantidadDisponible">
                            <input class="input is-small" type="number" name="cantidadDisponible"
                                [(ngModel)]="producto.cantidadDisponible" placeholder="{{labels.cantidadDisponible}}"
                                [disabled]="producto.id == 0 ? false : true">
                        </div>

                        <div class="control is-expanded"
                            [attr.data-tooltip]="producto.cantidadInterna == null ? null : labels.cantidadInterna">
                            <input [attr.data-tooltip]="labels.cantidadInterna" class="input is-small" type="number"
                                name="cantidadInterna" [(ngModel)]="producto.cantidadInterna"
                                placeholder="{{labels.cantidadInterna}}" [disabled]="producto.id == 0 ? false : true">
                        </div>

                        <div class="control" [attr.data-tooltip]="labels.agregarCompra">
                            <button class="button is-small landing" (click)="mostrarModalCompras(true)"
                                [style.visibility]="producto.id != 0 ? 'visible' : 'hidden'">
                                <span class="is-icon">
                                    <i class="fas fa-plus"></i>
                                </span>
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            <!-- FIN: Cantidad -->

            <!-- Precio -->

            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="level-left">{{labels.precio}}</label>
                </div>
                <div class="field-body">

                    <div class="field is-grouped">

                        <div class="control is-expanded">
                            <input class="input is-small has-text-right" type="text" name="precio"
                                [(ngModel)]="producto.precio">
                        </div>

                        <div class="control is-expanded">
                            <label class="star-checkbox">
                                <input type="checkbox" [checked]="producto.destacado"
                                    (change)="producto.destacado = $event.target.checked">
                                {{labels.destacado}}
                                <span class="star"></span>
                            </label>
                        </div>

                        <div class="control">
                            <button class="button is-small invisible">
                                <span class="is-icon">
                                    <i class="fas fa-plus"></i>
                                </span>
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            <!-- FIN: Precio -->

        </form>

        <div class="container-productos">
            <div #imagenCropped class="imagen-cropped-productos">
                <img *ngIf="croppedResult" [src]="croppedResult" alt="Imagen seleccionada">
            </div>
        </div>

    </div>

    <!-- Botones -->

    <div class="field is-grouped is-grouped-right boton-productos">

        <div class="control">
            <button class="button is-small is-outlined" (click)="guardar()">{{botones.guardar}}</button>
        </div>

        <div class="control">
            <button class="button is-small is-danger" [disabled]="producto.id == 0"
                (click)="deshabilitarProducto()">{{botones.eliminar}}</button>
        </div>

        <div class="control">
            <button class="button is-small" (click)="limpiarProducto()">
                <span class="icon has-text-primary"><i class="fas fa-eraser"></i></span>
            </button>
        </div>

    </div>

    <!-- FIN: Botones -->

</div>

<div #divTabla class="box">

    <div class="field">
        <div class="control has-icons-left">

            <app-input-timer [placeholder]="labels.buscar" [name]="filtroProductos"
                (textoCompleto)="onFilterTextChanged($event)">
            </app-input-timer>

        </div>
    </div>

    <div class="div-tabla is-size-7" [style.height.px]="altoDivTabla">

        <div class="tabla-encabezado">
            <table class="table is-bordered">

                <thead>
                    <tr>
                        <th #referencia width="15%">{{columnas.referencia}}</th>
                        <th #detalle width="75%">{{columnas.detalle}}</th>
                        <th width="10%">{{columnas.acciones}}</th>
                    </tr>
                </thead>

            </table>
        </div>

        <div class="div-cuerpo-tabla">
            <table class="table is-bordered is-fullwidth">

                <tbody>

                    <tr *ngFor="let producto of productos | filtroProductos: [ filtroProductos ]">
                        <td [style.width.px]="thReferencia">{{producto.referencia}}</td>
                        <td [style.width.px]="thDetalle">{{producto.detalle}}</td>
                        <td>

                            <div class="buttons is-centered">
                                <button class="button is-small landing" (click)="consultarPorId(producto.id)">
                                    <span class="icon">
                                        <i class="fas fa-edit"></i>
                                    </span>
                                </button>
                            </div>

                        </td>
                    </tr>

                </tbody>

            </table>

        </div>

    </div>

    <!-- Botones -->

    <div class="buttons is-right">

        <button class="button is-small is-outlined" (click)="abrirModalInformeSalidas()">
            <span>{{botones.informeSalidas}}</span>
            <span class="icon">
                <i class="fas fa-file-excel"></i>
            </span>
        </button>

        <button class="button is-small is-outlined" (click)="generarInformeExistencias()">
            <span class="icono">{{botones.informeExistencias}}</span>
            <span class="icon icono">
                <i class="fas fa-file-excel"></i>
            </span>
        </button>

    </div>

    <!-- FIN: Botones -->

</div>

<div class="modal" [ngClass]="modalInformeSalidas ? 'is-active' : ''">
    <div class="modal-background" (click)="cerrarModalInformeSalidas()">
    </div>
    <div class="modal-content modal-salidas">
        <div class="box">

            <!-- Rango de fechas -->

            <div class="field">
                <div class="field-label">
                    <label class="level-left">
                        {{labels.rangoFechas}}
                    </label>
                </div>
                <div class="field-body">

                    <div class="field is-grouped">

                        <div class="control is-expanded" [attr.data-tooltip]="labels.fechaInicial">
                            <input type="date" name="fechaInicialInformeSalidas" class="input is-small"
                                [(ngModel)]="fechaInicialInformeSalidas">
                        </div>

                        <div class="control is-expanded" [attr.data-tooltip]="labels.fechaFinal">
                            <input type="date" name="fechaFinalInformeSalidas" class="input is-small"
                                [(ngModel)]="fechaFinalInformeSalidas">
                        </div>

                    </div>

                </div>
            </div>

            <!-- FIN: Rango de fechas -->

            <!-- Botones -->

            <div class="buttons is-right">
                <button class="button is-small is-outlined" (click)="generarInformeSalidas()">
                    <span>{{botones.generarInforme}}</span>
                    <span class="icon">
                        <i class="fas fa-file-excel"></i>
                    </span>
                </button>
            </div>

            <!-- FIN: Botones -->

        </div>
    </div>
</div>

<div class="modal" [ngClass]="modalComprasActivo ? 'is-active' : ''">
    <div class="modal-background" (click)="mostrarModalCompras(false)"></div>
    <div class="modal-content">
        <div class="box">
            <app-compras [producto]="producto"></app-compras>
        </div>
    </div>
</div>

<div class="modal" [ngClass]="modalCropper ? 'is-active' : ''">
    <div class="modal-background" (click)="cerrarModalCropper()"></div>
    <div class="modal-content modal-productos">
        <div class="box">

            <div class="has-text-right">
                <a id="btn-cerrar-menu">
                    <span class="icon is-small has-text-dark" (click)="cerrarModalCropper()">
                        <i class="fas fa-times"></i>
                    </span>
                </a>
            </div>

            <div class="box" *ngIf="imagen">
                <angular-cropper #angularCropper class="cropper" [imageUrl]="imagen" [cropperOptions]="{
                    dragMode: 'move',
                    movable: false,
                    responsive: false,
                    aspectRatio: 290/290,
                    autoCropArea: 1,
                    restore: false,
                    guides: false,
                    center: false,
                    highlight: false,
                    cropBoxMovable: true,
                    cropBoxResizable: false,
                    toggleDragModeOnDblclick: false,
                    checkCrossOrigin: false
                }">
                </angular-cropper>

                <br>

                <div class="field">
                    <button class="button landing is-fullwidth" (click)="getCroppedImage()">
                        {{botones.aceptar}}
                    </button>
                </div>
            </div>

        </div>

    </div>
</div>