import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Compra } from "../models/compra";
import { Utilidades } from "../models/utilidades";

@Injectable({
    providedIn: 'root'
})
export class CompraService {

    private url: string = environment.url;

    constructor(
        private _http: HttpClient
    ) { }

    public consultarPorId(id: number): Observable<any> {
        const url = this.url +  'Compra';
        const token: string = Utilidades.obtenerUsuario().token;
        const parametros: HttpParams = new HttpParams().
        set('token', token).
        set('solicitud', 'consultar_por_id').
        set('id', id);
                
        return this._http.get(url, { params: parametros});
    }

    public consultarPorProducto(idProducto: number): Observable<any> {
        const url = this.url +  'Compra';
        const token: string = Utilidades.obtenerUsuario().token;
        const parametros: HttpParams = new HttpParams().
        set('token', token).
        set('id_producto', idProducto);

        return this._http.get(url, { params: parametros});
    }

    public crear(compra: Compra, idProducto: number): Observable<any> {
        const url = this.url +  'Compra';
        const token: string = Utilidades.obtenerUsuario().token;
        const parametros: HttpParams = new HttpParams().
        set('token', token).
        set('solicitud', 'crear').
        set('id_producto', idProducto).
        set('fecha', compra.fecha).
        set('es_cantidad_interna', compra.esCantidadInterna ? '1' : '0').
        set('cantidad', compra.cantidad);

        return this._http.post(url, parametros);
    }

    public actualizar(compra: Compra): Observable<any> {
        const url = this.url +  'Compra';
        const token: string = Utilidades.obtenerUsuario().token;
        const parametros: HttpParams = new HttpParams().
        set('token', token).
        set('id', compra.id).
        set('fecha', compra.fecha).
        set('es_cantidad_interna', compra.esCantidadInterna).
        set('cantidad', compra.cantidad);

        return this._http.post(url, parametros);
    }

    public eliminar(id: number): Observable<any> {
        const url = this.url +  'Compra';
        const token: string = Utilidades.obtenerUsuario().token;
        const parametros: HttpParams = new HttpParams().
        set('token', token).
        set('solicitud', 'eliminar').
        set('id', id);

        return this._http.post(url, parametros);
    }

}