<div #divForm class="div-form">
    <form autocomplete="off">

        <!-- Tipo de persona -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.persona}}</label>
            </div>
            <div class="field-body">
                <div class="field is-grouped">

                    <div class="control is-expanded">

                        <div class="select is-small is-fullwidth">
                            <app-select-tipos-persona [tipoPersona]="persona.tipo"
                                (tipoPersonaSeleccionado)="tipoPersonaSeleccionado($event)">
                            </app-select-tipos-persona>
                        </div>

                    </div>

                    <div class="control is-expanded">

                        <div class="select is-small is-fullwidth">

                            <app-select-tipos-documento [tipoDocumento]="persona.tipoDocumento"
                                (tipoDocumentoSeleccionado)="tipoDocumentoSeleccionado($event)">
                            </app-select-tipos-documento>

                        </div>

                    </div>

                    <div class="control is-expanded"
                        [attr.data-tooltip]="persona.numeroDocumento == null || persona.numeroDocumento.length == 0 ? null : labels.numeroDocumento">
                        <input class="input is-small is-uppercase is-uppercase" type="text"
                            placeholder="{{labels.numeroDocumento}}" name="numeroDocumento"
                            [(ngModel)]="persona.numeroDocumento">
                    </div>

                </div>

            </div>
        </div>

        <!-- FIN: Persona -->

        <!-- Nombre completo -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.nombreCompleto}}</label>
            </div>
            <div class="field-body">

                <div class="field is-grouped">
                    <div class="control is-expanded"
                        [attr.data-tooltip]="persona.nombres == null || persona.nombres.length == 0 ? null : labels.nombres">
                        <input class="input is-small is-uppercase" type="text" placeholder="{{labels.nombres}}"
                            name="nombres" [(ngModel)]="persona.nombres"
                            (focusout)="formatearNombres($event.originalTarget.name)">
                    </div>
                    <div class="control is-expanded"
                        [attr.data-tooltip]="persona.apellidos == null || persona.apellidos.length == 0 ? null : labels.apellidos">
                        <input class="input is-small is-uppercase" type="text" placeholder="{{labels.apellidos}}"
                            name="apellidos" [(ngModel)]="persona.apellidos"
                            (focusout)="formatearNombres($event.originalTarget.name)">
                    </div>
                </div>

            </div>
        </div>

        <!-- FIN: Nombre completo -->

        <!-- Razón social -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.razonSocial}}</label>
            </div>
            <div class="field-body">

                <input class="input is-small is-uppercase" type="text" name="razonSocial"
                    [(ngModel)]="persona.razonSocial" (focusout)="formatearNombres($event.originalTarget.name)">

            </div>
        </div>

        <!-- FIN: Razón social -->

        <!-- Domicilio -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.domicilio}}</label>
            </div>
            <div class="field-body">

                <div class="field is-grouped">

                    <div class="control">

                        <div class="select is-small">

                            <app-select-departamentos #selectDepartamentos
                                (departamentoSeleccionado)="departamentoSeleccionado($event)">
                            </app-select-departamentos>

                        </div>

                    </div>

                    <div class="control">

                        <div class="select is-small">

                            <app-select-municipios #selectMunicipios
                                (municipioSeleccionado)="municipioSeleccionado($event)">
                            </app-select-municipios>

                        </div>

                    </div>

                    <div class="control is-expanded"
                        [attr.data-tooltip]="persona.direccion == null || persona.direccion.length == 0 ? null : labels.direccion">
                        <input class="input is-small is-uppercase" type="text" placeholder="{{labels.direccion}}"
                            name="direccion" [(ngModel)]="persona.direccion">
                    </div>

                </div>

            </div>
        </div>

        <!-- FIN: Domicilio -->

        <!-- Contacto -->

        <div class="field is-horizontal">
            <div class="field-label is-normal">
                <label class="level-left">{{labels.contacto}}</label>
            </div>
            <div class="field-body">

                <div class="field is-grouped">
                    <div class="control is-expanded"
                        [attr.data-tooltip]="persona.telefono == null || persona.telefono.length == 0 ? null : labels.telefono">
                        <input class="input is-small is-uppercase" type="text" placeholder="{{labels.telefono}}"
                            name="telefono" [(ngModel)]="persona.telefono">
                    </div>
                    <div class="control is-expanded"
                        [attr.data-tooltip]="persona.email == null || persona.email.length == 0 ? null : labels.email">
                        <input class="input is-small is-lowercase" type="email" placeholder="{{labels.email}}"
                            name="email" [(ngModel)]="persona.email">
                    </div>
                </div>

            </div>
        </div>

        <!-- FIN: Contacto -->

        <div class="field is-grouped is-grouped-right">

            <div class="control">
                <button class="button is-small is-outlined" (click)="guardar()">
                    {{botones.guardar}}
                </button>
            </div>

            <div class="control" *ngIf="!creacionPersona">
                <button class="button is-small is-danger" [disabled]="persona.id == 0" (click)="eliminar()">
                    {{botones.eliminar}}
                </button>
            </div>

            <div class="control">
                <button class="button is-small" (click)="limpiar()">
                    <span class="icon has-text-primary"><i class="fas fa-eraser"></i></span>
                </button>
            </div>

        </div>

    </form>
</div>

<div class="box div-inferior" *ngIf="!creacionPersona">
    <app-personas #listadoPersonas (personaSeleccionada)="personaSeleccionada($event)">
    </app-personas>
</div>