export const environment = {
  production: true,
  url: 'https://appdecoraytransforma.co/decora_transforma/api/',
  tipoPersonaCliente: {
    id: 1,
    nombre: 'Cliente'
  },
  tipoPersonaVendedor: {
    id: 2,
    nombre: 'Vendedor'
  },
  tipoPersonaDistribuidor: {
    id: 4,
    nombre: 'Distribuidor'
  },
  tokenWeb: '296745b16755e718259a46c1b4e2a175'
};
